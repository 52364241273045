import React from 'react';
import './index.css';
import { connect } from 'react-redux';
import { hideSideBarDrawer, setDepositAmount, setTransactionType, showSideBarDrawer } from '../../../actions/navBar';
import * as PropTypes from 'prop-types';
import { Drawer } from '@mui/material';
import variables from '../../../utils/variables';
import CopyButton from '../../../components/CopyButton';
import { config } from '../../../config';
import { commaSeparator, splitDecimals } from '../../../utils/numbers';
import Button from '@mui/material/Button';
import { ReactComponent as DisconnectIcon } from '../../../assets/disconnect.svg';
import { ReactComponent as RightArrow } from '../../../assets/right-arrow.svg';
import copyIcon from '../../../assets/copy.svg';
import TokenSelectField from './TokenSelectField';
import AmountTextField from './AmountTextField';
import CloseIcon from '@mui/icons-material/Close';
import withRouter from '../../../components/WithRouter';
import { setDisconnect } from '../../../actions/account/wallet';
import { setTabStreams } from '../../../actions/streams';
import DotsLoading from '../../../components/DotsLoading';
import { showDepositeDialog, showWithdrawDialog } from '../../../actions/account/IBCTokens';
import ConnectWithUs from '../../Home/Create/ConnectWithUs';

class SideBarDrawer extends React.Component {
    constructor (props) {
        super(props);

        this.handleDisconnect = this.handleDisconnect.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handleHalf = this.handleHalf.bind(this);
        this.handleMax = this.handleMax.bind(this);
    }

    handleDisconnect () {
        this.props.handleClose();
        this.props.router.navigate('/');
        this.props.setTabStreams('');
        this.props.setDisconnect();
        localStorage.removeItem('stream_pay_address');
        localStorage.removeItem('stream_pay_cosmostation');
        localStorage.removeItem('stream_pay_leap');
        localStorage.removeItem('acToken_stream_pay');
        localStorage.removeItem('rfToken_stream_pay');
    }

    handleClick (value) {
        this.props.handleClose();
        if (value === 'my_streams') {
            this.props.router.navigate('/all');
            this.props.setTabStreams('all');
        } else if (value === 'ibc_token') {
            this.props.router.navigate('/ibc-tokens');
        }
    }

    handleHalf () {
        const denom = this.props.value && this.props.value.ibc_denom_hash;

        const decimals = this.props.value && this.props.value.network && this.props.value.network.decimals;

        let ibcBalance = this.props.balance && this.props.balance.length && this.props.balance.find((val) => val.denom === denom);
        ibcBalance = ibcBalance && ibcBalance.amount && ibcBalance.amount / (10 ** decimals);

        let depositBalance = this.props.ibcBalance && this.props.ibcBalance.length &&
            this.props.value && this.props.value.network && this.props.value.network.denom &&
            this.props.ibcBalance.find((val) => val.denom === this.props.value.network.denom);
        depositBalance = depositBalance && depositBalance.amount && depositBalance.amount / (10 ** decimals);

        if (this.props.activeTab === 'deposit') {
            if (depositBalance > 0) {
                this.props.setDepositAmount(String(depositBalance / 2));
            } else {
                this.props.setDepositAmount('0');
            }
        } else {
            if (ibcBalance > 0) {
                this.props.setDepositAmount(String(ibcBalance / 2));
            } else {
                this.props.setDepositAmount('0');
            }
        }
    }

    handleMax () {
        const denom = this.props.value && this.props.value.ibc_denom_hash;

        const decimals = this.props.value && this.props.value.network && this.props.value.network.decimals;

        let ibcBalance = this.props.balance && this.props.balance.length && this.props.balance.find((val) => val.denom === denom);
        ibcBalance = ibcBalance && ibcBalance.amount && ibcBalance.amount / (10 ** decimals);

        let depositBalance = this.props.ibcBalance && this.props.ibcBalance.length &&
            this.props.value && this.props.value.network && this.props.value.network.denom &&
            this.props.ibcBalance.find((val) => val.denom === this.props.value.network.denom);
        depositBalance = depositBalance && depositBalance.amount && depositBalance.amount / (10 ** decimals);

        if (this.props.activeTab === 'deposit') {
            if (depositBalance > 0) {
                this.props.setDepositAmount(String(depositBalance));
            } else {
                this.props.setDepositAmount('0');
            }
        } else {
            if (ibcBalance > 0) {
                this.props.setDepositAmount(String(ibcBalance));
            } else {
                this.props.setDepositAmount('0');
            }
        }
    }

    render () {
        const name = this.props.value && this.props.value.network && this.props.value.network.display_denom;

        const denom = this.props.value && this.props.value.ibc_denom_hash;

        const decimals = this.props.value && this.props.value.network && this.props.value.network.decimals;

        let balance = this.props.balance && this.props.balance.length && this.props.balance.find((val) => val.denom === config.COIN_MINIMAL_DENOM);
        balance = balance && balance.amount && splitDecimals(balance.amount / (10 ** config.COIN_DECIMALS));

        let ibcBalance = this.props.balance && this.props.balance.length && this.props.balance.find((val) => val.denom === denom);
        ibcBalance = ibcBalance && ibcBalance.amount && splitDecimals(ibcBalance.amount / (10 ** decimals));

        let balanceCheck = this.props.balance && this.props.balance.length && this.props.balance.find((val) => val.denom === denom);
        balanceCheck = balanceCheck && balanceCheck.amount && (balanceCheck.amount / (10 ** decimals));

        let depositBalance = this.props.ibcBalance && this.props.ibcBalance.length &&
            this.props.value && this.props.value.network && this.props.value.network.denom &&
            this.props.ibcBalance.find((val) => val.denom === this.props.value.network.denom);
        depositBalance = depositBalance && depositBalance.amount && splitDecimals(depositBalance.amount / (10 ** decimals));

        let depositBalanceCheck = this.props.ibcBalance && this.props.ibcBalance.length &&
            this.props.value && this.props.value.network && this.props.value.network.denom &&
            this.props.ibcBalance.find((val) => val.denom === this.props.value.network.denom);
        depositBalanceCheck = depositBalanceCheck && depositBalanceCheck.amount && (depositBalanceCheck.amount / (10 ** decimals));
        // const inProgress = this.props.accountInProgress || this.props.timeoutHeightInProgress || this.props.signInProgress;
        // const disable = this.props.amountValue < 0.1 || this.props.amountValue > balance || inProgress;
        const address = localStorage.getItem('stream_pay_address') || this.props.address;

        return (
            <Drawer
                anchor="right"
                className="side_panel"
                open={this.props.open}
                onClose={this.props.handleClose}>
                <div className="section1">
                    <div className="header">
                        <h2>{variables[this.props.lang].active_account}</h2>
                        <Button onClick={this.props.handleClose}>
                            <CloseIcon/>
                        </Button>
                    </div>
                    <div className="address_section">
                        <div className="hash_text" title={address}>
                            <p className="name">{address}</p>
                            {address && address.slice(address.length - 5, address.length)}
                        </div>
                        <CopyButton data={address} icon={copyIcon} />
                    </div>
                    <div className="amount_section">
                        {/* <NetworkImages name={config.COIN_MINIMAL_DENOM} /> */}
                        <p>
                            {this.props.balanceInProgress
                                ? <DotsLoading/>
                                : balance && balance.length
                                    ? <>
                                        {balance.length && balance[0] && commaSeparator(balance[0])}
                                        {balance.length && balance[1] &&
                                            <span>.{balance.length && balance[1]}</span>}
                                    </>
                                    : 0}
                        </p>
                        <span>{config.COIN_DENOM}&nbsp;</span>
                    </div>
                    {/* <div className="test_tokens"> */}
                    {/*    <Button> */}
                    {/*        {variables[this.props.lang].claim_test_tokens} */}
                    {/*    </Button> */}
                    {/* </div> */}
                </div>
                <div className="section2">
                    <div className="header">
                        <h2>{variables[this.props.lang].my_account}</h2>
                        <Button onClick={this.handleDisconnect}>
                            <DisconnectIcon/>
                            {variables[this.props.lang].disconnect}
                        </Button>
                    </div>
                    <div className="row" onClick={() => this.handleClick('my_streams')}>
                        <div>
                            <span>{variables[this.props.lang].my_streams}</span>
                            {!this.props.statsInProgress && this.props.stats && this.props.stats.my_streams
                                ? <p>{this.props.stats.my_streams}</p>
                                : null}
                        </div>
                        <RightArrow/>
                    </div>
                    <div className="row" onClick={() => this.handleClick('ibc_token')}>
                        <div>
                            <span>{variables[this.props.lang].tokens}</span>
                        </div>
                        <RightArrow/>
                    </div>
                    {/* <div */}
                    {/*     className="row" style={{ */}
                    {/*         opacity: 0.6, */}
                    {/*         disable: true, */}
                    {/*         cursor: 'unset', */}
                    {/*     }}> */}
                    {/*     <div> */}
                    {/*         <span>{variables[this.props.lang].activity}</span> */}
                    {/*     </div> */}
                    {/*     <RightArrow/> */}
                    {/* </div> */}
                </div>
                <div className="section2 section3">
                    <div className="header">
                        <h2>{variables[this.props.lang].ibc_deposit_withdraw}</h2>
                    </div>
                    <div className="tx_type">
                        <p>{variables[this.props.lang].transaction_type}</p>
                        <div>
                            <Button
                                className={this.props.activeTab === 'deposit' ? 'active' : ''}
                                onClick={() => this.props.setTransactionType('deposit')}>
                                {variables[this.props.lang].deposit}
                            </Button>
                            <Button
                                className={this.props.activeTab === 'withdraw' ? 'active' : ''}
                                onClick={() => this.props.setTransactionType('withdraw')}>
                                {variables[this.props.lang].withdraw}
                            </Button>
                        </div>
                    </div>
                    <div className="amount_deposit">
                        <div className="start">
                            <span>{variables[this.props.lang]['amount_to_deposit']}</span>
                            <div>
                                <Button onClick={this.handleHalf}>{variables[this.props.lang].half}</Button>
                                <Button onClick={this.handleMax}>{variables[this.props.lang].max}</Button>
                            </div>
                        </div>
                        <div className="fields">
                            <TokenSelectField/>
                            <AmountTextField/>
                        </div>
                        <div className="end">
                            {this.props.balanceInProgress
                                ? <span>{variables[this.props.lang].available}{' = '}
                                    <DotsLoading/>
                                </span>
                                : this.props.activeTab === 'deposit' && depositBalance && depositBalance.length
                                    ? <span>{variables[this.props.lang].available}{' = '}
                                        <>
                                            {depositBalance.length && depositBalance[0] && commaSeparator(depositBalance[0])}
                                            {depositBalance.length && depositBalance[1] &&
                                                <span>.{depositBalance.length && depositBalance[1]}</span>}
                                            &nbsp;{name}
                                        </>
                                    </span>
                                    : ibcBalance && ibcBalance.length
                                        ? <span>{variables[this.props.lang].available}{' = '}
                                            <>
                                                {ibcBalance.length && ibcBalance[0] && commaSeparator(ibcBalance[0])}
                                                {ibcBalance.length && ibcBalance[1] &&
                                                <span>.{ibcBalance.length && ibcBalance[1]}</span>}
                                            &nbsp;{name}
                                            </>
                                        </span> : null}
                        </div>
                    </div>
                    {this.props.value && this.props.value.network
                        ? <div className="address">
                            <div className="head">
                                <span>{variables[this.props.lang].from}</span>
                                <span>{variables[this.props.lang].to}</span>
                            </div>
                            {this.props.activeTab === 'deposit'
                                ? <div className="address_details">
                                    <div className="hash_text" title={this.props.ibcAddress}>
                                        <p className="name">{this.props.ibcAddress}</p>
                                        {this.props.ibcAddress &&
                                        this.props.ibcAddress.slice(this.props.ibcAddress.length - 6, this.props.ibcAddress.length)}
                                    </div>
                                    <div className="hash_text" title={this.props.address}>
                                        <p className="name">{this.props.address}</p>
                                        {this.props.address &&
                                        this.props.address.slice(this.props.address.length - 6, this.props.address.length)}
                                    </div>
                                </div>
                                : <div className="address_details">
                                    <div className="hash_text" title={this.props.address}>
                                        <p className="name">{this.props.address}</p>
                                        {this.props.address &&
                                        this.props.address.slice(this.props.address.length - 6, this.props.address.length)}
                                    </div>
                                    <div className="hash_text" title={this.props.ibcAddress}>
                                        <p className="name">{this.props.ibcAddress}</p>
                                        {this.props.ibcAddress &&
                                        this.props.ibcAddress.slice(this.props.ibcAddress.length - 6, this.props.ibcAddress.length)}
                                    </div>
                                </div>}
                        </div> : null}
                    <div className="actions">
                        {this.props.activeTab === 'deposit'
                            ? <Button
                                disabled={!this.props.amount || (Number(this.props.amount) > depositBalanceCheck)}
                                onClick={() => this.props.showDepositeDialog(this.props.value)}>
                                {variables[this.props.lang].deposit}
                            </Button>
                            : this.props.activeTab === 'withdraw'
                                ? <Button
                                    disabled={!this.props.amount || (Number(this.props.amount) > balanceCheck)}
                                    onClick={() => this.props.showWithdrawDialog(this.props.value)}>
                                    {variables[this.props.lang].withdraw}
                                </Button>
                                : null}
                    </div>
                </div>
                <div className="mobile_view">
                    <ConnectWithUs/>
                </div>
            </Drawer>
        );
    }
}

SideBarDrawer.propTypes = {
    activeTab: PropTypes.string.isRequired,
    address: PropTypes.string.isRequired,
    amount: PropTypes.any.isRequired,
    balance: PropTypes.array.isRequired,
    balanceInProgress: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    ibcAddress: PropTypes.string.isRequired,
    ibcBalance: PropTypes.array.isRequired,
    lang: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    setDepositAmount: PropTypes.func.isRequired,
    setDisconnect: PropTypes.func.isRequired,
    setTabStreams: PropTypes.func.isRequired,
    setTransactionType: PropTypes.func.isRequired,
    showDepositeDialog: PropTypes.func.isRequired,
    showSideBarDrawer: PropTypes.func.isRequired,
    showWithdrawDialog: PropTypes.func.isRequired,
    stats: PropTypes.object.isRequired,
    statsInProgress: PropTypes.bool.isRequired,
    router: PropTypes.shape({
        navigate: PropTypes.func.isRequired,
    }),
    value: PropTypes.any,
};

const stateToProps = (state) => {
    return {
        amount: state.navBar.amount.value,
        address: state.account.wallet.connection.address,
        ibcAddress: state.account.ibc.connection.address,
        lang: state.language,
        open: state.navBar.sideBarDrawer.open,
        value: state.navBar.token.value,

        balance: state.account.bc.balance.value,
        balanceInProgress: state.account.bc.balance.inProgress,
        ibcBalance: state.account.ibc.balance.value,

        stats: state.streams.streamsStats.value,
        statsInProgress: state.streams.streamsStats.inProgress,

        activeTab: state.navBar.transactionType.value,

        // timeoutHeightInProgress: state.account.ibc.timeoutHeight.inProgress,
        // accountInProgress: state.account.connection.inProgress,
        // inProgress: state.account.ibc.connection.inProgress,
        // signInProgress: state.account.ibc.connection.signInProgress,
    };
};

const actionToProps = {
    handleClose: hideSideBarDrawer,

    setDisconnect,
    setTabStreams,
    showSideBarDrawer,
    setTransactionType,
    setDepositAmount,
    showWithdrawDialog,
    showDepositeDialog,
};

export default withRouter(connect(stateToProps, actionToProps)(SideBarDrawer));
