import * as PropTypes from 'prop-types';
import {
    initializeChain,
    initializeLeap,
    setDisconnect,
} from '../../actions/account/wallet';
import withRouter from '../../components/WithRouter';
import { connect } from 'react-redux';
import React from 'react';
import logoIcon from '../../assets/logo.svg';
import './index.css';
import ConnectButton from './ConnectButton';
import { fetchAllowances, fetchBalance } from '../../actions/account/BCDetails';
import { setTabStreams } from '../../actions/streams';
import SideBarDrawer from './SideBarDrawer';
import WithDrawDialog from './SideBarDrawer/WithDrawDialog';
import DepositDialog from './SideBarDrawer/DepositeDialog';
import { initializeCosmoStation } from '../../actions/account/cosmostation';
import ConnectDialog from './ConnectDialog';
import { fetchIBCTokensList } from '../../actions/create';

class NavBar extends React.Component {
    constructor (props) {
        super(props);

        this.initializeKeplr = this.initializeKeplr.bind(this);
        this.initKeplr = this.initKeplr.bind(this);
        this.handleRedirect = this.handleRedirect.bind(this);
    }

    componentDidMount () {
        if (this.props.ibcTokensList.length === 0 && !this.props.ibcTokensListInProgress) {
            this.props.fetchIBCTokensList();
        }

        const address = localStorage.getItem('stream_pay_address') || this.props.address;
        if (this.props.address === '' && localStorage.getItem('stream_pay_address')) {
            if (localStorage.getItem('stream_pay_cosmostation')) {
                setTimeout(() => {
                    this.initializeCosmoStation();
                }, 600);

                return;
            }
            if (localStorage.getItem('stream_pay_leap')) {
                setTimeout(() => {
                    this.initializeLeap();
                }, 600);

                return;
            }
            // used set time out to omit the image loading issue with window.onload
            setTimeout(() => {
                this.initializeKeplr();
            }, 600);
        } else {
            if ((address) &&
                (this.props.balance.length === 0) && !this.props.balanceInProgress) {
                this.props.fetchBalance(address);
            }
            if (address && this.props.allowances &&
                (this.props.allowances.length === 0) && !this.props.allowancesInProgress) {
                this.props.fetchAllowances(address);
            }
        }

        if (localStorage.getItem('stream_pay_cosmostation')) {
            window.cosmostation.cosmos.on('accountChanged', () => {
                this.props.setDisconnect();
                this.initCosmoStation();
                localStorage.removeItem('acToken_stream_pay');
                localStorage.removeItem('stream_pay_address');
                // if (this.props.faucetDialog) {
                //     this.handleIBCList();
                // }
            });
        } else if (localStorage.getItem('stream_pay_leap')) {
            window.addEventListener('leap_keystorechange', () => {
                this.props.setDisconnect();
                this.initLeap();
                localStorage.removeItem('acToken_stream_pay');
                localStorage.removeItem('stream_pay_address');
                // if (this.props.faucetDialog) {
                //     this.handleIBCList();
                // }
            });
        } else {
            window.addEventListener('keplr_keystorechange', () => {
                this.props.setDisconnect();
                this.initKeplr();
                localStorage.removeItem('acToken_stream_pay');
                localStorage.removeItem('stream_pay_address');
                // if (this.props.faucetDialog) {
                //     this.handleIBCList();
                // }
            });
        }
    }

    componentWillUnmount () {
        window.removeEventListener('keplr_keystorechange', this.initKeplr);
        window.removeEventListener('leap_keystorechange', this.initLeap);
    }

    initializeKeplr () {
        this.props.initializeChain((address) => {
            if (!address) {
                window.onload = () => this.initializeKeplr();
                return;
            }

            localStorage.setItem('stream_pay_address', address && address.length && address[0] && address[0].address);
            if ((address && address.length && address[0] && address[0].address) &&
                (this.props.balance.length === 0) && !this.props.balanceInProgress) {
                this.props.fetchBalance(address[0].address);
            }
            if ((address && address.length && address[0] && address[0].address) &&
                this.props.allowances && (this.props.allowances.length === 0) && !this.props.allowancesInProgress) {
                this.props.fetchAllowances(address[0].address);
            }
        });
    }

    initKeplr () {
        this.props.initializeChain((address) => {
            localStorage.setItem('stream_pay_address', address && address.length && address[0] && address[0].address);
            this.props.fetchBalance(address[0].address);
            this.props.fetchAllowances(address[0].address);
        });
    }

    initCosmoStation () {
        this.props.initializeCosmoStation((error, account) => {
            if (error) {
                return;
            }

            localStorage.setItem('stream_pay_address', account && account.address);
            this.props.fetchBalance(account.address);
            this.props.fetchAllowances(account.address);
        });
    }

    initializeCosmoStation () {
        this.props.initializeCosmoStation((error, account) => {
            if (error) {
                return;
            }

            if (!account) {
                window.onload = () => this.initializeCosmoStation();
                return;
            }

            localStorage.setItem('stream_pay_address', account && account.address);
            if ((account && account.address) &&
                (this.props.balance.length === 0) && !this.props.balanceInProgress) {
                this.props.fetchBalance(account.address);
            }
            if ((account && account.address) &&
                this.props.allowances && (this.props.allowances.length === 0) && !this.props.allowancesInProgress) {
                this.props.fetchAllowances(account.address);
            }
        });
    }

    initLeap () {
        this.props.initializeLeap((address) => {
            localStorage.setItem('stream_pay_address', address && address.length && address[0] && address[0].address);
            this.props.fetchBalance(address[0].address);
            this.props.fetchAllowances(address[0].address);
        });
    }

    initializeLeap () {
        this.props.initializeLeap((address) => {
            if (!address) {
                window.onload = () => this.initializeLeap();
                return;
            }

            localStorage.setItem('stream_pay_address', address && address.length && address[0] && address[0].address);
            if ((address && address.length && address[0] && address[0].address) &&
                (this.props.balance.length === 0) && !this.props.balanceInProgress) {
                this.props.fetchBalance(address[0].address);
            }
            if ((address && address.length && address[0] && address[0].address) &&
                this.props.allowances && (this.props.allowances.length === 0) && !this.props.allowancesInProgress) {
                this.props.fetchAllowances(address[0].address);
            }
            localStorage.setItem('stream_pay_leap', 'leap');
        });
    }

    handleRedirect () {
        if (!this.props.address) {
            this.props.router.navigate('/');
        } else {
            this.props.router.navigate('/all');
            this.props.setTabStreams('all');
        }
    }

    render () {
        return (
            <div className="nav_bar">
                <div>
                    <div className="left_section" onClick={this.handleRedirect}>
                        <img alt="logo" src={logoIcon}/>
                    </div>
                    <div className="right_section">
                        <ConnectButton/>
                    </div>
                </div>
                <SideBarDrawer/>
                <WithDrawDialog/>
                <DepositDialog/>
                <ConnectDialog/>
            </div>
        );
    }
}

NavBar.propTypes = {
    address: PropTypes.string.isRequired,
    allowances: PropTypes.array.isRequired,
    allowancesInProgress: PropTypes.bool.isRequired,
    balance: PropTypes.array.isRequired,
    balanceInProgress: PropTypes.bool.isRequired,
    fetchAllowances: PropTypes.func.isRequired,
    fetchBalance: PropTypes.func.isRequired,
    fetchIBCTokensList: PropTypes.func.isRequired,
    ibcTokensList: PropTypes.array.isRequired,
    ibcTokensListInProgress: PropTypes.bool.isRequired,
    initializeChain: PropTypes.func.isRequired,
    initializeCosmoStation: PropTypes.func.isRequired,
    initializeLeap: PropTypes.func.isRequired,
    keys: PropTypes.object.isRequired,
    lang: PropTypes.string.isRequired,
    setDisconnect: PropTypes.func.isRequired,
    setTabStreams: PropTypes.func.isRequired,
    router: PropTypes.shape({
        navigate: PropTypes.func.isRequired,
    }),
};

const stateToProps = (state) => {
    return {
        address: state.account.wallet.connection.address,
        lang: state.language,

        balance: state.account.bc.balance.value,
        balanceInProgress: state.account.bc.balance.inProgress,
        keys: state.account.wallet.connection.keys,
        allowances: state.account.bc.allowances.value,
        allowancesInProgress: state.account.bc.allowances.inProgress,

        ibcTokensList: state.create.ibcTokensList.value,
        ibcTokensListInProgress: state.create.ibcTokensList.inProgress,
    };
};

const actionToProps = {
    fetchBalance,
    fetchAllowances,
    setDisconnect,
    setTabStreams,
    fetchIBCTokensList,

    initializeChain,
    initializeCosmoStation,
    initializeLeap,
};

export default withRouter(connect(stateToProps, actionToProps)(NavBar));
