import React from 'react';
import variables from '../../../utils/variables';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import TwitterLogo from '../../../assets/social/twitter.svg';
import TelegramLogo from '../../../assets/social/telegram.svg';
import DiscordLogo from '../../../assets/social/discord.svg';
import GithubLogo from '../../../assets/social/github.svg';
import WebsiteLogo from '../../../assets/social/website.svg';
import YoutubeLogo from '../../../assets/social/youtube.svg';
import { Button } from '@mui/material';

const ConnectWithUs = (props) => {
    const handleClick = (url) => {
        window.open(url);
    };

    return (
        <div className="connect_with_us">
            <h2 className="header2_common"> {variables[props.lang]['connect_with_us']}</h2>
            <div className="social_icons">
                <Button onClick={() => handleClick('https://twitter.com/OmniFlixNetwork')}>
                    {/* <TwitterLogo/> */}
                    <img alt="twitter" src={TwitterLogo}/>
                </Button>
                <Button onClick={() => handleClick('https://t.me/OmniFlixChat')}>
                    {/* <TelegramLogo/> */}
                    <img alt="telegram" src={TelegramLogo}/>
                </Button>
                <Button onClick={() => handleClick('https://discord.com/invite/6gdQ4yZSTC')}>
                    {/* <DiscordLogo/> */}
                    <img alt="discord" src={DiscordLogo}/>
                </Button>
                <Button onClick={() => handleClick('https://github.com/OmniFlix')}>
                    {/* <GithubLogo/> */}
                    <img alt="github" src={GithubLogo}/>
                </Button>
                <Button onClick={() => handleClick('https://omniflix.network')}>
                    {/* <WebsiteLogo/> */}
                    <img alt="website" src={WebsiteLogo}/>
                </Button>
                <Button onClick={() => handleClick('https://youtube.com/OmniFlixNetwork')}>
                    {/* <YoutubeLogo/> */}
                    <img alt="youtube" src={YoutubeLogo}/>
                </Button>
            </div>
        </div>
    );
};

ConnectWithUs.propTypes = {
    lang: PropTypes.string.isRequired,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
    };
};

export default connect(stateToProps)(ConnectWithUs);
