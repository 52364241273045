export const SEARCH_STREAMS_SET = 'SEARCH_STREAMS_SET';
export const TAB_STREAMS_SET = 'TAB_STREAMS_SET';
export const FILTER_DIALOG_SHOW = 'FILTER_DIALOG_SHOW';
export const FILTER_DIALOG_HIDE = 'FILTER_DIALOG_HIDE';

export const ALL_STREAMS_SUCCESS = 'ALL_STREAMS_SUCCESS';
export const ALL_STREAMS_IN_PROGRESS = 'ALL_STREAMS_IN_PROGRESS';
export const ALL_STREAMS_ERROR = 'ALL_STREAMS_ERROR';

export const ACTIVE_STREAMS_SUCCESS = 'ACTIVE_STREAMS_SUCCESS';
export const ACTIVE_STREAMS_IN_PROGRESS = 'ACTIVE_STREAMS_IN_PROGRESS';
export const ACTIVE_STREAMS_ERROR = 'ACTIVE_STREAMS_ERROR';

export const COMPLETED_STREAMS_SUCCESS = 'COMPLETED_STREAMS_SUCCESS';
export const COMPLETED_STREAMS_IN_PROGRESS = 'COMPLETED_STREAMS_IN_PROGRESS';
export const COMPLETED_STREAMS_ERROR = 'COMPLETED_STREAMS_ERROR';

export const STOPPED_STREAMS_SUCCESS = 'STOPPED_STREAMS_SUCCESS';
export const STOPPED_STREAMS_IN_PROGRESS = 'STOPPED_STREAMS_IN_PROGRESS';
export const STOPPED_STREAMS_ERROR = 'STOPPED_STREAMS_ERROR';

export const FETCH_STREAM_INFO_SUCCESS = 'FETCH_STREAM_INFO_SUCCESS';
export const FETCH_STREAM_INFO_IN_PROGRESS = 'FETCH_STREAM_INFO_IN_PROGRESS';
export const FETCH_STREAM_INFO_ERROR = 'FETCH_STREAM_INFO_ERROR';

export const FETCH_STREAMS_STATS_SUCCESS = 'FETCH_STREAMS_STATS_SUCCESS';
export const FETCH_STREAMS_STATS_IN_PROGRESS = 'FETCH_STREAMS_STATS_IN_PROGRESS';
export const FETCH_STREAMS_STATS_ERROR = 'FETCH_STREAMS_STATS_ERROR';

export const STREAMS_FILTER_VALUE_SET = 'STREAMS_FILTER_VALUE_SET';
