/* eslint-disable */
import * as Long from "long";
import * as _m0 from "protobufjs/minimal";
import { Coin } from "../../cosmos/base/v1beta1/coin";
import { Timestamp } from "../../google/protobuf/timestamp";

export const protobufPackage = "OmniFlix.streampay.v1";

export enum StreamType {
  STREAM_PAYMENT_TYPE_DELAYED = 0,
  STREAM_PAYMENT_TYPE_CONTINUOUS = 1,
  STREAM_PAYMENT_TYPE_PERIODIC = 2,
  UNRECOGNIZED = -1,
}

export function streamTypeFromJSON(object: any): StreamType {
  switch (object) {
    case 0:
    case "STREAM_PAYMENT_TYPE_DELAYED":
      return StreamType.STREAM_PAYMENT_TYPE_DELAYED;
    case 1:
    case "STREAM_PAYMENT_TYPE_CONTINUOUS":
      return StreamType.STREAM_PAYMENT_TYPE_CONTINUOUS;
    case 2:
    case "STREAM_PAYMENT_TYPE_PERIODIC":
      return StreamType.STREAM_PAYMENT_TYPE_PERIODIC;
    case -1:
    case "UNRECOGNIZED":
    default:
      return StreamType.UNRECOGNIZED;
  }
}

export function streamTypeToJSON(object: StreamType): string {
  switch (object) {
    case StreamType.STREAM_PAYMENT_TYPE_DELAYED:
      return "STREAM_PAYMENT_TYPE_DELAYED";
    case StreamType.STREAM_PAYMENT_TYPE_CONTINUOUS:
      return "STREAM_PAYMENT_TYPE_CONTINUOUS";
    case StreamType.STREAM_PAYMENT_TYPE_PERIODIC:
      return "STREAM_PAYMENT_TYPE_PERIODIC";
    case StreamType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum StreamStatus {
  STREAM_PAYMENT_STATUS_ACTIVE = 0,
  STREAM_PAYMENT_STATUS_COMPLETED = 1,
  STREAM_PAYMENT_STATUS_CANCELLED = 2,
  UNRECOGNIZED = -1,
}

export function streamStatusFromJSON(object: any): StreamStatus {
  switch (object) {
    case 0:
    case "STREAM_PAYMENT_STATUS_ACTIVE":
      return StreamStatus.STREAM_PAYMENT_STATUS_ACTIVE;
    case 1:
    case "STREAM_PAYMENT_STATUS_COMPLETED":
      return StreamStatus.STREAM_PAYMENT_STATUS_COMPLETED;
    case 2:
    case "STREAM_PAYMENT_STATUS_CANCELLED":
      return StreamStatus.STREAM_PAYMENT_STATUS_CANCELLED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return StreamStatus.UNRECOGNIZED;
  }
}

export function streamStatusToJSON(object: StreamStatus): string {
  switch (object) {
    case StreamStatus.STREAM_PAYMENT_STATUS_ACTIVE:
      return "STREAM_PAYMENT_STATUS_ACTIVE";
    case StreamStatus.STREAM_PAYMENT_STATUS_COMPLETED:
      return "STREAM_PAYMENT_STATUS_COMPLETED";
    case StreamStatus.STREAM_PAYMENT_STATUS_CANCELLED:
      return "STREAM_PAYMENT_STATUS_CANCELLED";
    case StreamStatus.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface StreamPayment {
  id: string;
  sender: string;
  recipient: string;
  totalAmount: Coin | undefined;
  streamType: StreamType;
  periods: Period[];
  cancellable: boolean;
  startTime: Date | undefined;
  endTime: Date | undefined;
  streamedAmount: Coin | undefined;
  lastClaimedAt: Date | undefined;
  status: StreamStatus;
}

export interface Period {
  amount: number;
  duration: number;
}

function createBaseStreamPayment(): StreamPayment {
  return {
    id: "",
    sender: "",
    recipient: "",
    totalAmount: undefined,
    streamType: 0,
    periods: [],
    cancellable: false,
    startTime: undefined,
    endTime: undefined,
    streamedAmount: undefined,
    lastClaimedAt: undefined,
    status: 0,
  };
}

export const StreamPayment = {
  encode(message: StreamPayment, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.sender !== "") {
      writer.uint32(18).string(message.sender);
    }
    if (message.recipient !== "") {
      writer.uint32(26).string(message.recipient);
    }
    if (message.totalAmount !== undefined) {
      Coin.encode(message.totalAmount, writer.uint32(34).fork()).ldelim();
    }
    if (message.streamType !== 0) {
      writer.uint32(40).int32(message.streamType);
    }
    for (const v of message.periods) {
      Period.encode(v!, writer.uint32(50).fork()).ldelim();
    }
    if (message.cancellable === true) {
      writer.uint32(56).bool(message.cancellable);
    }
    if (message.startTime !== undefined) {
      Timestamp.encode(toTimestamp(message.startTime), writer.uint32(66).fork()).ldelim();
    }
    if (message.endTime !== undefined) {
      Timestamp.encode(toTimestamp(message.endTime), writer.uint32(74).fork()).ldelim();
    }
    if (message.streamedAmount !== undefined) {
      Coin.encode(message.streamedAmount, writer.uint32(82).fork()).ldelim();
    }
    if (message.lastClaimedAt !== undefined) {
      Timestamp.encode(toTimestamp(message.lastClaimedAt), writer.uint32(90).fork()).ldelim();
    }
    if (message.status !== 0) {
      writer.uint32(96).int32(message.status);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): StreamPayment {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStreamPayment();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.sender = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.recipient = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.totalAmount = Coin.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.streamType = reader.int32() as any;
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.periods.push(Period.decode(reader, reader.uint32()));
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.cancellable = reader.bool();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.startTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.endTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.streamedAmount = Coin.decode(reader, reader.uint32());
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.lastClaimedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): StreamPayment {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      sender: isSet(object.sender) ? globalThis.String(object.sender) : "",
      recipient: isSet(object.recipient) ? globalThis.String(object.recipient) : "",
      totalAmount: isSet(object.totalAmount) ? Coin.fromJSON(object.totalAmount) : undefined,
      streamType: isSet(object.streamType) ? streamTypeFromJSON(object.streamType) : 0,
      periods: globalThis.Array.isArray(object?.periods) ? object.periods.map((e: any) => Period.fromJSON(e)) : [],
      cancellable: isSet(object.cancellable) ? globalThis.Boolean(object.cancellable) : false,
      startTime: isSet(object.startTime) ? fromJsonTimestamp(object.startTime) : undefined,
      endTime: isSet(object.endTime) ? fromJsonTimestamp(object.endTime) : undefined,
      streamedAmount: isSet(object.streamedAmount) ? Coin.fromJSON(object.streamedAmount) : undefined,
      lastClaimedAt: isSet(object.lastClaimedAt) ? fromJsonTimestamp(object.lastClaimedAt) : undefined,
      status: isSet(object.status) ? streamStatusFromJSON(object.status) : 0,
    };
  },

  toJSON(message: StreamPayment): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.sender !== "") {
      obj.sender = message.sender;
    }
    if (message.recipient !== "") {
      obj.recipient = message.recipient;
    }
    if (message.totalAmount !== undefined) {
      obj.totalAmount = Coin.toJSON(message.totalAmount);
    }
    if (message.streamType !== 0) {
      obj.streamType = streamTypeToJSON(message.streamType);
    }
    if (message.periods?.length) {
      obj.periods = message.periods.map((e) => Period.toJSON(e));
    }
    if (message.cancellable === true) {
      obj.cancellable = message.cancellable;
    }
    if (message.startTime !== undefined) {
      obj.startTime = message.startTime.toISOString();
    }
    if (message.endTime !== undefined) {
      obj.endTime = message.endTime.toISOString();
    }
    if (message.streamedAmount !== undefined) {
      obj.streamedAmount = Coin.toJSON(message.streamedAmount);
    }
    if (message.lastClaimedAt !== undefined) {
      obj.lastClaimedAt = message.lastClaimedAt.toISOString();
    }
    if (message.status !== 0) {
      obj.status = streamStatusToJSON(message.status);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<StreamPayment>, I>>(base?: I): StreamPayment {
    return StreamPayment.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<StreamPayment>, I>>(object: I): StreamPayment {
    const message = createBaseStreamPayment();
    message.id = object.id ?? "";
    message.sender = object.sender ?? "";
    message.recipient = object.recipient ?? "";
    message.totalAmount = (object.totalAmount !== undefined && object.totalAmount !== null)
      ? Coin.fromPartial(object.totalAmount)
      : undefined;
    message.streamType = object.streamType ?? 0;
    message.periods = object.periods?.map((e) => Period.fromPartial(e)) || [];
    message.cancellable = object.cancellable ?? false;
    message.startTime = object.startTime ?? undefined;
    message.endTime = object.endTime ?? undefined;
    message.streamedAmount = (object.streamedAmount !== undefined && object.streamedAmount !== null)
      ? Coin.fromPartial(object.streamedAmount)
      : undefined;
    message.lastClaimedAt = object.lastClaimedAt ?? undefined;
    message.status = object.status ?? 0;
    return message;
  },
};

function createBasePeriod(): Period {
  return { amount: 0, duration: 0 };
}

export const Period = {
  encode(message: Period, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.amount !== 0) {
      writer.uint32(8).int64(message.amount);
    }
    if (message.duration !== 0) {
      writer.uint32(16).int64(message.duration);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Period {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePeriod();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.amount = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.duration = longToNumber(reader.int64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Period {
    return {
      amount: isSet(object.amount) ? globalThis.Number(object.amount) : 0,
      duration: isSet(object.duration) ? globalThis.Number(object.duration) : 0,
    };
  },

  toJSON(message: Period): unknown {
    const obj: any = {};
    if (message.amount !== 0) {
      obj.amount = Math.round(message.amount);
    }
    if (message.duration !== 0) {
      obj.duration = Math.round(message.duration);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Period>, I>>(base?: I): Period {
    return Period.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Period>, I>>(object: I): Period {
    const message = createBasePeriod();
    message.amount = object.amount ?? 0;
    message.duration = object.duration ?? 0;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = Math.trunc(date.getTime() / 1_000);
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = (t.seconds || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new globalThis.Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof globalThis.Date) {
    return o;
  } else if (typeof o === "string") {
    return new globalThis.Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function longToNumber(long: Long): number {
  if (long.gt(globalThis.Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
