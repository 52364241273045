import {
    AMOUNT_VALUE_SET,
    CLEAR_CREATE_STREAM_SET,
    END_DATE_SET,
    IBC_TOKENS_LIST_FETCH_ERROR,
    IBC_TOKENS_LIST_FETCH_IN_PROGRESS,
    IBC_TOKENS_LIST_FETCH_SUCCESS,
    NUMBER_OF_PERIODS_SET,
    STOP_STREAMING_SET,
    TO_ADDRESS_SET,
    TOKEN_VALUE_SET,
    TYPE_VALUE_SET,
} from '../constants/create';
import Axios from 'axios';
import { IBC_TOKENS_LIST_URL } from '../constants/url';
import { setDepositToken } from './navBar';

export const setToAddress = (value, valid) => {
    return {
        type: TO_ADDRESS_SET,
        value,
        valid,
    };
};

export const setTypeValue = (value) => {
    return {
        type: TYPE_VALUE_SET,
        value,
    };
};

export const setToken = (value) => {
    return {
        type: TOKEN_VALUE_SET,
        value,
    };
};

export const setAmount = (value) => {
    return {
        type: AMOUNT_VALUE_SET,
        value,
    };
};

export const setEndDate = (value, valid) => {
    return {
        type: END_DATE_SET,
        value,
        valid,
    };
};

export const setStopStreaming = (value) => {
    return {
        type: STOP_STREAMING_SET,
        value,
    };
};

export const setNumberOfPeriods = (value) => {
    return {
        type: NUMBER_OF_PERIODS_SET,
        value,
    };
};

export const setClearCreateStream = () => {
    return {
        type: CLEAR_CREATE_STREAM_SET,
    };
};

const fetchIBCTokensListInProgress = () => {
    return {
        type: IBC_TOKENS_LIST_FETCH_IN_PROGRESS,
    };
};

const fetchIBCTokensListSuccess = (value) => {
    return {
        type: IBC_TOKENS_LIST_FETCH_SUCCESS,
        value,
    };
};

const fetchIBCTokensListError = (message) => {
    return {
        type: IBC_TOKENS_LIST_FETCH_ERROR,
        message,
    };
};

export const fetchIBCTokensList = (cb) => (dispatch) => {
    dispatch(fetchIBCTokensListInProgress());

    Axios.get(IBC_TOKENS_LIST_URL, {
        headers: {
            Accept: 'application/json, text/plain, */*',
        },
    })
        .then((res) => {
            dispatch(fetchIBCTokensListSuccess(res.data && res.data.result));
            dispatch(setDepositToken(res.data && res.data.result && res.data.result.length && res.data.result[0]));
            if (cb) {
                cb(res.data && res.data.result);
            }
        })
        .catch((error) => {
            dispatch(fetchIBCTokensListError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
            if (cb) {
                cb(null);
            }
        });
};
