import { combineReducers } from 'redux';
import {
    ALL_CLAIMABLE_STREAMS_ERROR,
    ALL_CLAIMABLE_STREAMS_IN_PROGRESS,
    ALL_CLAIMABLE_STREAMS_SUCCESS,
    CLAIM_ALL_DIALOG_HIDE,
    CLAIM_ALL_DIALOG_SHOW,
} from '../constants/claims';

const claimableStreams = (state = {
    inProgress: false,
    list: [],
}, action) => {
    switch (action.type) {
    case ALL_CLAIMABLE_STREAMS_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case ALL_CLAIMABLE_STREAMS_SUCCESS:
        return {
            ...state,
            inProgress: false,
            list: action.value,
        };
    case ALL_CLAIMABLE_STREAMS_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const claimAllDialog = (state = {
    open: false,
    value: {},
}, action) => {
    switch (action.type) {
    case CLAIM_ALL_DIALOG_SHOW:
        return {
            open: true,
            value: action.value,
        };
    case CLAIM_ALL_DIALOG_HIDE:
        return {
            open: false,
            value: {},
        };

    default:
        return state;
    }
};

export default combineReducers({
    claimableStreams,
    claimAllDialog,
});
