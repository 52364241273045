import { combineReducers } from 'redux';
import BCDetails from './BCDetails';
import wallet from './wallet';
import IBCTokens from './IBCTokens';
import {
    IBC_TOKENS_LIST_FETCH_ERROR,
    IBC_TOKENS_LIST_FETCH_IN_PROGRESS,
    IBC_TOKENS_LIST_FETCH_SUCCESS,
} from '../../constants/create';
import { tokensList } from '../../utils/tokensList';

const chainID = (state = {
    inProgress: false,
    value: tokensList[0],
    list: [],
}, action) => {
    switch (action.type) {
    // case CHAIN_ID_SET:
    //     return {
    //         ...state,
    //         value: action.value,
    //     };
    case IBC_TOKENS_LIST_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case IBC_TOKENS_LIST_FETCH_SUCCESS: {
        const array = [...action.value];
        return {
            ...state,
            inProgress: false,
            list: [...array],
        };
    }
    case IBC_TOKENS_LIST_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

export default combineReducers({
    chainID,
    wallet,
    bc: BCDetails,
    ibc: IBCTokens,
});
