import React from 'react';
import { Button } from '@mui/material';
import variables from '../../utils/variables';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { initializeChain, setDisconnect } from '../../actions/account/wallet';
import withRouter from '../../components/WithRouter';
import { config } from '../../config';
import DotsLoading from '../../components/DotsLoading';
import { fetchAllowances, fetchBalance } from '../../actions/account/BCDetails';
import { setTabStreams } from '../../actions/streams';
import MenuIcon from '@mui/icons-material/Menu';
import { showConnectDialog, showSideBarDrawer } from '../../actions/navBar';

const ConnectButton = (props) => {
    const handleClick = () => {
        props.showSideBarDrawer();
    };

    // const initKeplr = () => {
    //     props.initializeChain((address) => {
    //         if (address.length && address[0] && address[0].address) {
    //             localStorage.setItem('stream_pay_address', address && address.length && address[0] && address[0].address);
    //             props.router.navigate('/all');
    //             props.fetchBalance(address[0] && address[0].address);
    //             props.fetchAllowances(address[0] && address[0].address);
    //             window.addEventListener('keplr_keystorechange', () => {
    //                 initKeplr();
    //             });
    //         }
    //     });
    // };

    const balance = props.balance && props.balance.length &&
        props.balance.find((val) => val.denom === config.COIN_MINIMAL_DENOM);
    const address = localStorage.getItem('stream_pay_address') || props.address;
    return (
        <div className="account_info">
            <div className="mobile_view">
                {address
                    ? <Button className="menu" onClick={handleClick}>
                        <MenuIcon/>
                    </Button>
                    : <Button className="connect_button" variant="contained" onClick={props.showConnectDialog}>
                        {variables[props.lang].connect}
                    </Button>}
            </div>
            <div className="desk_view">
                {address
                    ? <div className="connected_button" onClick={handleClick}>
                        <div className="profile_details">
                            {props.balanceInProgress
                                ? <DotsLoading/>
                                : balance && balance.amount
                                    ? <p className="balance">
                                        {balance.amount / (10 ** config.COIN_DECIMALS)} {config.COIN_DENOM}
                                    </p>
                                    : <p className="balance">
                                        0 {config.COIN_DENOM}
                                    </p>}
                            {props.inProgress && address === ''
                                ? <DotsLoading/>
                                : <div className="hash_text" title={address}>
                                    <p className="name">{address}</p>
                                    {address && address.slice(address.length - 5, address.length)}
                                </div>}

                        </div>
                        <p className="profile_image"/>
                    </div>
                    : <Button className="connect_button" variant="contained" onClick={props.showConnectDialog}>
                        {variables[props.lang].connect}
                    </Button>}
            </div>
        </div>
    );
};

ConnectButton.propTypes = {
    address: PropTypes.string.isRequired,
    balance: PropTypes.array.isRequired,
    balanceInProgress: PropTypes.bool.isRequired,
    fetchAllowances: PropTypes.func.isRequired,
    fetchBalance: PropTypes.func.isRequired,
    inProgress: PropTypes.bool.isRequired,
    initializeChain: PropTypes.func.isRequired,
    lang: PropTypes.string.isRequired,
    setDisconnect: PropTypes.func.isRequired,
    setTabStreams: PropTypes.func.isRequired,
    showConnectDialog: PropTypes.func.isRequired,
    showSideBarDrawer: PropTypes.func.isRequired,
    router: PropTypes.shape({
        navigate: PropTypes.func.isRequired,
    }),
};

const stateToProps = (state) => {
    return {
        address: state.account.wallet.connection.address,
        lang: state.language,
        inProgress: state.account.wallet.connection.inProgress,

        balance: state.account.bc.balance.value,
        balanceInProgress: state.account.bc.balance.inProgress,
    };
};

const actionToProps = {
    initializeChain,
    fetchBalance,
    fetchAllowances,

    setDisconnect,
    setTabStreams,
    showSideBarDrawer,
    showConnectDialog,
};

export default withRouter(connect(stateToProps, actionToProps)(ConnectButton));
