import { combineReducers } from 'redux';
import {
    ACTIVE_STREAMS_ERROR,
    ACTIVE_STREAMS_IN_PROGRESS,
    ACTIVE_STREAMS_SUCCESS,
    ALL_STREAMS_ERROR,
    ALL_STREAMS_IN_PROGRESS,
    ALL_STREAMS_SUCCESS,
    COMPLETED_STREAMS_ERROR,
    COMPLETED_STREAMS_IN_PROGRESS,
    COMPLETED_STREAMS_SUCCESS,
    FETCH_STREAM_INFO_ERROR,
    FETCH_STREAM_INFO_IN_PROGRESS,
    FETCH_STREAM_INFO_SUCCESS,
    FETCH_STREAMS_STATS_ERROR,
    FETCH_STREAMS_STATS_IN_PROGRESS,
    FETCH_STREAMS_STATS_SUCCESS,
    FILTER_DIALOG_HIDE,
    FILTER_DIALOG_SHOW,
    SEARCH_STREAMS_SET,
    STOPPED_STREAMS_ERROR,
    STOPPED_STREAMS_IN_PROGRESS,
    STOPPED_STREAMS_SUCCESS, STREAMS_FILTER_VALUE_SET,
    TAB_STREAMS_SET,
} from '../constants/streams';
import { DEFAULT_LIMIT, DEFAULT_SKIP } from '../config';

const searchStreams = (state = {
    value: '',
}, action) => {
    switch (action.type) {
    case SEARCH_STREAMS_SET:
        return {
            ...state,
            value: action.value,
        };

    default:
        return state;
    }
};

const tabStreams = (state = {
    value: 'all',
}, action) => {
    switch (action.type) {
    case TAB_STREAMS_SET:
        return {
            ...state,
            value: action.value,
        };

    default:
        return state;
    }
};

const filterDialog = (state = {
    open: false,
}, action) => {
    switch (action.type) {
    case FILTER_DIALOG_SHOW:
        return {
            open: true,
        };
    case FILTER_DIALOG_HIDE:
        return {
            open: false,
        };

    default:
        return state;
    }
};

const filterValue = (state = {
    value: {},
}, action) => {
    switch (action.type) {
    case STREAMS_FILTER_VALUE_SET:
        return {
            value: action.value,
        };

    default:
        return state;
    }
};

const activeStreams = (state = {
    inProgress: false,
    list: [],
    count: null,
    skip: DEFAULT_SKIP,
    limit: DEFAULT_LIMIT,
}, action) => {
    switch (action.type) {
    case ACTIVE_STREAMS_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case ACTIVE_STREAMS_SUCCESS:
        if (action.skip === DEFAULT_SKIP) {
            return {
                ...state,
                inProgress: false,
                list: action.value,
                skip: action.limit - DEFAULT_LIMIT,
                limit: DEFAULT_LIMIT,
                count: action.count,
            };
        } else {
            return {
                ...state,
                inProgress: false,
                list: [...state.list, ...action.value],
                skip: action.skip,
                limit: action.limit,
                count: action.count,
            };
        }
    case ACTIVE_STREAMS_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const completedStreams = (state = {
    inProgress: false,
    list: [],
    count: null,
    skip: DEFAULT_SKIP,
    limit: DEFAULT_LIMIT,
}, action) => {
    switch (action.type) {
    case COMPLETED_STREAMS_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case COMPLETED_STREAMS_SUCCESS:
        if (action.skip === DEFAULT_SKIP) {
            return {
                ...state,
                inProgress: false,
                list: action.value,
                skip: action.limit - DEFAULT_LIMIT,
                limit: DEFAULT_LIMIT,
                count: action.count,
            };
        } else {
            return {
                ...state,
                inProgress: false,
                list: [...state.list, ...action.value],
                skip: action.skip,
                limit: action.limit,
                count: action.count,
            };
        }
    case COMPLETED_STREAMS_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const stoppedStreams = (state = {
    inProgress: false,
    list: [],
    count: null,
    skip: DEFAULT_SKIP,
    limit: DEFAULT_LIMIT,
}, action) => {
    switch (action.type) {
    case STOPPED_STREAMS_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case STOPPED_STREAMS_SUCCESS:
        if (action.skip === DEFAULT_SKIP) {
            return {
                ...state,
                inProgress: false,
                list: action.value,
                skip: action.limit - DEFAULT_LIMIT,
                limit: DEFAULT_LIMIT,
                count: action.count,
            };
        } else {
            return {
                ...state,
                inProgress: false,
                list: [...state.list, ...action.value],
                skip: action.skip,
                limit: action.limit,
                count: action.count,
            };
        }
    case STOPPED_STREAMS_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const streamInfo = (state = {
    inProgress: false,
    value: {},
}, action) => {
    switch (action.type) {
    case FETCH_STREAM_INFO_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
            value: {},
        };
    case FETCH_STREAM_INFO_SUCCESS:
        return {
            inProgress: false,
            value: action.value,
        };
    case FETCH_STREAM_INFO_ERROR:
        return {
            ...state,
            value: {},
        };
    default:
        return state;
    }
};

const allStreams = (state = {
    inProgress: false,
    list: [],
    count: null,
    skip: DEFAULT_SKIP,
    limit: DEFAULT_LIMIT,
}, action) => {
    switch (action.type) {
    case ALL_STREAMS_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case ALL_STREAMS_SUCCESS:
        if (action.skip === DEFAULT_SKIP) {
            return {
                ...state,
                inProgress: false,
                list: action.value,
                skip: action.limit - DEFAULT_LIMIT,
                limit: DEFAULT_LIMIT,
                count: action.count,
            };
        } else {
            return {
                ...state,
                inProgress: false,
                list: [...state.list, ...action.value],
                skip: action.skip,
                limit: action.limit,
                count: action.count,
            };
        }
    case ALL_STREAMS_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const streamsStats = (state = {
    inProgress: false,
    value: {},
}, action) => {
    switch (action.type) {
    case FETCH_STREAMS_STATS_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
            value: {},
        };
    case FETCH_STREAMS_STATS_SUCCESS:
        return {
            inProgress: false,
            value: action.value,
        };
    case FETCH_STREAMS_STATS_ERROR:
        return {
            ...state,
            value: {},
        };
    default:
        return state;
    }
};

export default combineReducers({
    searchStreams,
    tabStreams,
    filterDialog,
    filterValue,
    activeStreams,
    completedStreams,
    stoppedStreams,
    streamInfo,
    allStreams,
    streamsStats,
});
