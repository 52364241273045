import { MsgClaimStreamedAmount, MsgStopStream, MsgStreamSend } from './omniflix_custom_ts_types/streampay/v1/tx';

export const customTypes = {
    StreamSend: {
        typeUrl: '/OmniFlix.streampay.v1.MsgStreamSend',
        type: MsgStreamSend,
    },
    StopStream: {
        typeUrl: '/OmniFlix.streampay.v1.MsgStopStream',
        type: MsgStopStream,
    },
    ClaimStreamedAmount: {
        typeUrl: '/OmniFlix.streampay.v1.MsgClaimStreamedAmount',
        type: MsgClaimStreamedAmount,
    },
};

export const customRegistry = [[
    customTypes.StreamSend.typeUrl,
    customTypes.StreamSend.type,
], [
    customTypes.StopStream.typeUrl,
    customTypes.StopStream.type,
], [
    customTypes.ClaimStreamedAmount.typeUrl,
    customTypes.ClaimStreamedAmount.type,
]];
