import * as PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import variables from '../../../utils/variables';
import SelectField from '../../../components/SelectField';
import { fetchIBCTokensList } from '../../../actions/create';
import { images } from '../../../components/NetworkImages/ImagesOptions';
import { setDepositToken } from '../../../actions/navBar';
import { connectIBCAccount, fetchIBCBalance } from '../../../actions/account/IBCTokens';

class TokenSelectField extends Component {
    constructor (props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount () {
        if (this.props.ibcTokensList.length === 0 && !this.props.ibcTokensListInProgress) {
            this.props.fetchIBCTokensList((result) => {
                if (result && result.length && result[0]) {
                    const config = {
                        RPC_URL: result[0] && result[0].network && result[0].network.rpc_address,
                        REST_URL: result[0] && result[0].network && result[0].network.api_address,
                        CHAIN_ID: result[0] && result[0].network && result[0].network.chain_id,
                        CHAIN_NAME: result[0] && result[0].network && result[0].network.name,
                        COIN_DENOM: result[0] && result[0].network && result[0].network.display_denom,
                        COIN_MINIMAL_DENOM: result[0] && result[0].network && result[0].network.denom,
                        COIN_DECIMALS: result[0] && result[0].network && result[0].network.decimals,
                        PREFIX: result[0] && result[0].network && result[0].network.address_prefix,
                    };

                    this.initKeplr(config);
                }
            });
        } else if (this.props.ibcTokensList.length && this.props.ibcTokensList[0]) {
            const config = {
                RPC_URL: this.props.ibcTokensList[0] && this.props.ibcTokensList[0].network && this.props.ibcTokensList[0].network.rpc_address,
                REST_URL: this.props.ibcTokensList[0] && this.props.ibcTokensList[0].network && this.props.ibcTokensList[0].network.api_address,
                CHAIN_ID: this.props.ibcTokensList[0] && this.props.ibcTokensList[0].network && this.props.ibcTokensList[0].network.chain_id,
                CHAIN_NAME: this.props.ibcTokensList[0] && this.props.ibcTokensList[0].network && this.props.ibcTokensList[0].network.name,
                COIN_DENOM: this.props.ibcTokensList[0] && this.props.ibcTokensList[0].network && this.props.ibcTokensList[0].network.display_denom,
                COIN_MINIMAL_DENOM: this.props.ibcTokensList[0] && this.props.ibcTokensList[0].network && this.props.ibcTokensList[0].network.denom,
                COIN_DECIMALS: this.props.ibcTokensList[0] && this.props.ibcTokensList[0].network && this.props.ibcTokensList[0].network.decimals,
                PREFIX: this.props.ibcTokensList[0] && this.props.ibcTokensList[0].network && this.props.ibcTokensList[0].network.address_prefix,
            };

            this.initKeplr(config);
        }
    }

    handleChange (value) {
        this.props.onChange(value);

        const config = {
            RPC_URL: value && value.network && value.network.rpc_address,
            REST_URL: value && value.network && value.network.api_address,
            CHAIN_ID: value && value.network && value.network.chain_id,
            CHAIN_NAME: value && value.network && value.network.name,
            COIN_DENOM: value && value.network && value.network.display_denom,
            COIN_MINIMAL_DENOM: value && value.network && value.network.denom,
            COIN_DECIMALS: value && value.network && value.network.decimals,
            PREFIX: value && value.network && value.network.address_prefix,
        };

        this.initKeplr(config);
    }

    initKeplr (config) {
        this.props.connectIBCAccount(config, (address) => {
            this.props.fetchIBCBalance(config.REST_URL, address[0].address);
        });
    }

    render () {
        const list = this.props.ibcTokensList && this.props.ibcTokensList.length &&
            this.props.ibcTokensList.filter((val) => val.status === 'ENABLED' &&
                (val.network && val.network.display_denom !== 'ATOM/CH-0'));

        const options = [];
        if (list && list.length) {
            options.push(...list);
        }

        return (
            <SelectField
                id="token"
                images={images}
                name="token"
                options={options}
                placeholder={variables[this.props.lang]['select_token']}
                value={this.props.value}
                onChange={this.handleChange}/>
        );
    }
}

TokenSelectField.propTypes = {
    address: PropTypes.string.isRequired,
    connectIBCAccount: PropTypes.func.isRequired,
    fetchIBCBalance: PropTypes.func.isRequired,
    fetchIBCTokensList: PropTypes.func.isRequired,
    ibcTokensList: PropTypes.array.isRequired,
    ibcTokensListInProgress: PropTypes.bool.isRequired,
    lang: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.any,
};

const stateToProps = (state) => {
    return {
        ibcTokensList: state.create.ibcTokensList.value,
        ibcTokensListInProgress: state.create.ibcTokensList.inProgress,
        lang: state.language,
        value: state.navBar.token.value,
        address: state.account.wallet.connection.address,
    };
};

const actionsToProps = {
    connectIBCAccount,
    fetchIBCTokensList,
    fetchIBCBalance,
    onChange: setDepositToken,
};

export default connect(stateToProps, actionsToProps)(TokenSelectField);
