import * as PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import TextField from '../../../components/TextField';
import variables from '../../../utils/variables';
// import { InputAdornment } from '@mui/material';
// import { config } from '../../../config';
import { setDepositAmount } from '../../../actions/navBar';

const AmountTextField = (props) => {
    // let balance = props.token && props.token.denom && props.balance &&
    //     props.balance.length && props.balance.find((val) => val.denom === props.token.denom);
    // balance = balance && balance.amount && balance.amount / (10 ** config.COIN_DECIMALS);
    // const disabled = props.address === '' && !localStorage.getItem('of_hAtom_address');

    const handleChange = (value) => {
        props.onChange(value);
    };

    // const parts = props.value && props.value.split('.');
    // const value = parts && parts[0].replace(/\D/g, '');
    // const decimal = parts[1];
    //
    // let newValue = new Intl.NumberFormat('en-EN').format(value);
    //
    // // Prevent non-numeric decimal
    // if (!isNaN(decimal)) {
    //     newValue = `${newValue}.${decimal}`;
    // }
    //
    // const amount = value === '' && newValue === '0' ? props.value : newValue;
    return (
        <TextField
            disable={false}
            id="price"
            // inputProps={{
            //     endAdornment: (
            //         props.token && <InputAdornment position="end" onClick={() => props.onChange(balance || 0)}>
            //             <span className="amount_end"> {variables[props.lang].max} </span>
            //         </InputAdornment>
            //     ),
            // }}
            name="price"
            placeholder={variables[props.lang]['enter_amount']}
            // type="number"
            value={props.value}
            onChange={handleChange}/>
    );
};

AmountTextField.propTypes = {
    address: PropTypes.string.isRequired,
    balance: PropTypes.array.isRequired,
    lang: PropTypes.string.isRequired,
    value: PropTypes.any.isRequired,
    onChange: PropTypes.func.isRequired,
    token: PropTypes.any,
};

const stateToProps = (state) => {
    return {
        balance: state.account.bc.balance.value,
        lang: state.language,
        value: state.navBar.amount.value,
        token: state.navBar.token.value,
        address: state.account.wallet.connection.address,
    };
};

const actionsToProps = {
    onChange: setDepositAmount,
};

export default connect(stateToProps, actionsToProps)(AmountTextField);
