import {
    CONNECT_DIALOG_HIDE,
    CONNECT_DIALOG_SHOW,
    DEPOSIT_ADDRESS_VALUE_SET,
    DEPOSIT_AMOUNT_VALUE_SET,
    DEPOSIT_TOKEN_VALUE_SET,
    RESET_IBC_TRANSACTION_SET,
    SIDE_BAR_DRAWER_HIDE,
    SIDE_BAR_DRAWER_SHOW,
    TRANSACTION_TYPE_SET,
} from '../constants/navBar';

export const showSideBarDrawer = () => {
    return {
        type: SIDE_BAR_DRAWER_SHOW,
    };
};

export const hideSideBarDrawer = () => {
    return {
        type: SIDE_BAR_DRAWER_HIDE,
    };
};

export const setDepositToken = (value) => {
    return {
        type: DEPOSIT_TOKEN_VALUE_SET,
        value,
    };
};

export const setDepositAmount = (value) => {
    return {
        type: DEPOSIT_AMOUNT_VALUE_SET,
        value,
    };
};

export const setDepositAddress = (value) => {
    return {
        type: DEPOSIT_ADDRESS_VALUE_SET,
        value,
    };
};

export const setTransactionType = (value) => {
    return {
        type: TRANSACTION_TYPE_SET,
        value,
    };
};

export const setIBCTransactionReset = () => {
    return {
        type: RESET_IBC_TRANSACTION_SET,
    };
};

export const showConnectDialog = () => {
    return {
        type: CONNECT_DIALOG_SHOW,
    };
};

export const hideConnectDialog = () => {
    return {
        type: CONNECT_DIALOG_HIDE,
    };
};
