import React from 'react';
import * as PropTypes from 'prop-types';
import atomToken from '../../assets/tokens/atom.svg';
import dvpnToken from '../../assets/tokens/dvpn.svg';
import junoToken from '../../assets/tokens/juno.svg';
import aktToken from '../../assets/tokens/akash.svg';
import huahuaToken from '../../assets/tokens/chihuahua.svg';
import croToken from '../../assets/tokens/crypto.com.svg';
import gravitonToken from '../../assets/tokens/gravityBridge.svg';
import kiToken from '../../assets/tokens/KI.svg';
import likeToken from '../../assets/tokens/like.svg';
import osmoToken from '../../assets/tokens/osmosis.svg';
import rowanToken from '../../assets/tokens/sifchain.svg';
import starsToken from '../../assets/tokens/stargaze.png';
import usdcToken from '../../assets/tokens/usdc.svg';
import flixIcon from '../../assets/tokens/flix.svg';
import { connect } from 'react-redux';
import { CircularProgress } from '@mui/material';

const NetworkImages = (props) => {
    let imageIcon = null;
    if (props.ibcTokensList && props.ibcTokensList.length) {
        props.ibcTokensList.map((value) => {
            if (value && value.network && value.network.display_denom && value.image_URL &&
                (value.network.display_denom === props.name)) {
                imageIcon = value.image_URL;
            }

            return null;
        });
    }

    switch (props.name) {
    case 'FLIX':
    case 'uflix':
        imageIcon = flixIcon;
        break;
    case 'AKT':
    case 'uakt':
        imageIcon = aktToken;
        break;
    case 'ATOM':
    case 'uatom':
    case 'ibc/27394FB092D2ECCD56123C74F36E4C1F926001CEADA9CA97EA622B25F41E5EB2':
        imageIcon = atomToken;
        break;
    case 'DVPN':
    case 'udvpn':
        imageIcon = dvpnToken;
        break;
    case 'CRO':
        imageIcon = croToken;
        break;
    case 'GRAVITON':
        imageIcon = gravitonToken;
        break;
    case 'HUAHUA':
    case 'uhuahua':
        imageIcon = huahuaToken;
        break;
    case 'JUNO':
    case 'ujuno':
        imageIcon = junoToken;
        break;
    case 'LIKE':
    case 'nanolike':
        imageIcon = likeToken;
        break;
    case 'OSMO':
    case 'uosmo':
        imageIcon = osmoToken;
        break;
    case 'ROWAN':
        imageIcon = rowanToken;
        break;
    case 'STARS':
        imageIcon = starsToken;
        break;
    case 'USDC':
    case 'axlUSDC':
    case 'ibc/D189335C6E4A68B513C10AB227BF1C1D38C746766278BA3EEB4FB14124F1D858':
        imageIcon = usdcToken;
        break;
    case 'XKI':
        imageIcon = kiToken;
        break;
    default:
        break;
    }

    if (props.ibcTokensListInProgress) {
        return <CircularProgress className="token_circular_progress"/>;
    } else {
        return props.name !== '' && imageIcon && <img alt={props.alt ? props.alt : 'icon'} src={imageIcon}/>;
    }
};

NetworkImages.propTypes = {
    ibcTokensList: PropTypes.array.isRequired,
    ibcTokensListInProgress: PropTypes.bool.isRequired,
    alt: PropTypes.string,
    name: PropTypes.string,
};

const stateToProps = (state) => {
    return {
        ibcTokensList: state.create.ibcTokensList.value,
        ibcTokensListInProgress: state.create.ibcTokensList.inProgress,
    };
};

export default connect(stateToProps)(NetworkImages);
