export const SIDE_BAR_DRAWER_SHOW = 'SIDE_BAR_DRAWER_SHOW';
export const SIDE_BAR_DRAWER_HIDE = 'SIDE_BAR_DRAWER_HIDE';

export const DEPOSIT_TOKEN_VALUE_SET = 'DEPOSIT_TOKEN_VALUE_SET';
export const DEPOSIT_AMOUNT_VALUE_SET = 'DEPOSIT_AMOUNT_VALUE_SET';
export const DEPOSIT_ADDRESS_VALUE_SET = 'DEPOSIT_ADDRESS_VALUE_SET';
export const TRANSACTION_TYPE_SET = 'TRANSACTION_TYPE_SET';

export const RESET_IBC_TRANSACTION_SET = 'RESET_IBC_TRANSACTION_SET';

export const CONNECT_DIALOG_SHOW = 'CONNECT_DIALOG_SHOW';
export const CONNECT_DIALOG_HIDE = 'CONNECT_DIALOG_HIDE';
