import { config } from '../config';

export const IBC_TOKENS_LIST_URL = `${config.DATA_LAYER}/tokens`;
export const STREAM_PAY_PARAMS_URL = `${config.REST_URL}/omniflix/streampay/v1/params`;
export const urlStreamInfo = (id) => `${config.DATA_LAYER}/streams/${id}`;

export const urlStreamsStats = (address) => `${config.DATA_LAYER}/stats/streampay?address=${address}`;
export const urlVerifyAccount = (userId) =>
    `${URL}/users/${userId}/verify-bc-account`;
export const CONNECT_ACCOUNT_URL = `${URL}/users/connect-bc-account`;

export const urlStreams = (address, status, skip, limit, obj, sortBy, order) => {
    const params = [];

    if (address !== '') {
        params.push(`address=${address}`);
    }
    if (status && status === 'ongoing') {
        params.push(`status=${status}`);
    } else if (status !== '') {
        params.push(`statuses[]=${status}`);
    }
    if (sortBy && sortBy.length > 0) {
        params.push(`sortBy=${sortBy}`);

        if (order) {
            params.push(`order=${order}`);
        }
    }
    if (skip) {
        params.push(`skip=${skip}`);
    }
    if (limit) {
        params.push(`limit=${limit}`);
    }

    if (obj && obj.incoming) {
        params.push(`recipient=${address}`);
    }
    if (obj && obj.outgoing) {
        params.push(`sender=${address}`);
    }
    if (obj && obj.continuous) {
        params.push('types[]=STREAM_PAYMENT_TYPE_CONTINUOUS');
    }
    if (obj && obj.delayed) {
        params.push('types[]=STREAM_PAYMENT_TYPE_DELAYED');
    }
    if (obj && obj.periodic) {
        params.push('types[]=STREAM_PAYMENT_TYPE_PERIODIC');
    }
    if (obj && obj.completed) {
        params.push('status=completed');
    }
    if (obj && obj.ongoing) {
        params.push('status=ongoing');
    }
    if (obj && obj.upcoming) {
        params.push('status=upcoming');
    }
    if (obj && obj.active) {
        params.push('statuses[]=STREAM_PAYMENT_STATUS_ACTIVE');
    }
    if (obj && obj.statuses_completed) {
        params.push('statuses[]=STREAM_PAYMENT_STATUS_COMPLETED');
    }
    if (obj && obj.cancelled) {
        params.push('statuses[]=STREAM_PAYMENT_STATUS_CANCELLED');
    }

    return `${config.DATA_LAYER}/streams?${params.join('&')}`;
};

export const urlClaimDetails = (streamId, skip, limit, sortBy, order) => {
    const params = [];

    if (sortBy && sortBy.length > 0) {
        params.push(`sortBy=${sortBy}`);

        if (order) {
            params.push(`order=${order}`);
        }
    }
    if (skip) {
        params.push(`skip=${skip}`);
    }
    if (limit) {
        params.push(`limit=${limit}`);
    }

    return `${config.DATA_LAYER}/streams/${streamId}/claims?${params.join('&')}`;
};
export const urlAllClaimableStreams = (address) => {
    const params = ['types[]=STREAM_PAYMENT_TYPE_DELAYED', 'types[]=STREAM_PAYMENT_TYPE_CONTINUOUS',
        'statuses[]=STREAM_PAYMENT_STATUS_ACTIVE', 'all=true'];

    if (address !== '') {
        params.push(`address=${address}`);
        params.push(`recipient=${address}`);
    }

    return `${config.DATA_LAYER}/streams?${params.join('&')}`;
};
