import React, { Component } from 'react';
import { Button, Dialog } from '@mui/material';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import variables from '../../../utils/variables';
import {
    aminoSignIBCTx,
    aminoSignIBCTxCosmoStation,
    connectIBCAccount,
    fetchIBCBalance,
    fetchTimeoutHeight,
    hideDepositeDialog,
    setIBCTxInProgress,
} from '../../../actions/account/IBCTokens';
import { fetchBalance } from '../../../actions/account/BCDetails';
import Long from 'long';
import { config } from '../../../config';
import NetworkImages from '../../../components/NetworkImages';
import { showMessage } from '../../../actions/snackbar';
import { fetchTxHash, setTxHashInProgressFalse } from '../../../actions/account/wallet';
import { setIBCTransactionReset } from '../../../actions/navBar';

class DepositDialog extends Component {
    constructor (props) {
        super(props);

        this.state = {
            config: {},
        };
    }

    componentDidUpdate (pp, ps, ss) {
        if ((pp.open !== this.props.open) && this.props.open) {
            const config = {
                RPC_URL: this.props.value && this.props.value.network && this.props.value.network.rpc_address,
                REST_URL: this.props.value && this.props.value.network && this.props.value.network.api_address,
                CHAIN_ID: this.props.value && this.props.value.network && this.props.value.network.chain_id,
                CHAIN_NAME: this.props.value && this.props.value.network && this.props.value.network.name,
                COIN_DENOM: this.props.value && this.props.value.network && this.props.value.network.display_denom,
                COIN_MINIMAL_DENOM: this.props.value && this.props.value.network && this.props.value.network.denom,
                COIN_DECIMALS: this.props.value && this.props.value.network && this.props.value.network.decimals,
                PREFIX: this.props.value && this.props.value.network && this.props.value.network.address_prefix,
            };

            this.setState({
                config: config,
            });
            // this.initKeplr(config);
        }
    }

    // initKeplr (config) {
    //     this.props.connectIBCAccount(config, (address) => {
    //         this.props.fetchIBCBalance(config.REST_URL, address[0].address);
    //     });
    // }

    handleDeposit (decimals) {
        this.props.fetchTimeoutHeight(config.REST_URL, this.props.value && this.props.value.channel, (result) => {
            const revisionNumber = result && result.proof_height && result.proof_height.revision_number &&
                Long.fromNumber(result.proof_height.revision_number);
            const revisionHeight = result && result.proof_height && result.proof_height.revision_height;

            const data = {
                msg: {
                    typeUrl: '/ibc.applications.transfer.v1.MsgTransfer',
                    value: {
                        source_port: 'transfer',
                        source_channel: this.props.value && this.props.value.destination_channel,
                        token: {
                            denom: this.props.value && this.props.value.network && this.props.value.network.denom,
                            amount: String(this.props.amountValue * (10 ** decimals)),
                        },
                        sender: this.props.ibcAddress,
                        receiver: this.props.address,
                        timeout_height: {
                            revisionNumber: revisionNumber || undefined,
                            revisionHeight: Long.fromNumber(parseInt(revisionHeight) + 150) || undefined,
                        },
                        timeout_timestamp: undefined,
                    },
                },
                fee: {
                    amount: [{
                        amount: String(455000),
                        denom: this.props.value && this.props.value.network && this.props.value.network.denom,
                    }],
                    gas: String(450000),
                },
                memo: '',
            };

            const denom = this.props.value && this.props.value.ibc_denom_hash;
            let balance = this.props.mainBalance && this.props.mainBalance.length && denom &&
                this.props.mainBalance.find((val) => val.denom === denom);
            balance = balance && balance.amount;
            const explorer = this.props.value && this.props.value.network && this.props.value.network.explorer;

            if (localStorage.getItem('stream_pay_cosmostation')) {
                data.fee = {
                    amount: [{
                        amount: String(10000),
                        denom: this.props.value && this.props.value.network && this.props.value.network.denom,
                    }],
                    gas: String(450000),
                };

                this.props.aminoSignIBCTxCosmoStation(this.state.config, data, 'cosmostation', (result) => {
                    if (result) {
                        this.props.setIBCTxInProgress(true);
                        this.props.fetchIBCBalance(this.props.value && this.props.value.network && this.props.value.network.api_address,
                            this.props.ibcAddress);
                        this.props.fetchBalance(this.props.address);
                        this.props.setIBCTransactionReset();
                        const time = setInterval(() => {
                            this.props.fetchBalance(this.props.address, (resBalance) => {
                                let resultBalance = resBalance && resBalance.length && denom &&
                                    resBalance.find((val) => val.denom === denom);
                                resultBalance = resultBalance && resultBalance.amount;
                                if (resultBalance !== balance) {
                                    this.props.setIBCTxInProgress(false);
                                    this.props.showMessage('Transaction Successful', 'success', result && result.transactionHash, explorer);
                                    this.props.fetchBalance(this.props.address);
                                    clearInterval(time);
                                }
                            });
                        }, 5000);
                        this.props.handleClose();
                    }
                });

                return;
            }

            if (localStorage.getItem('stream_pay_leap')) {
                this.props.aminoSignIBCTxCosmoStation(this.state.config, data, 'leap', (result) => {
                    if (result) {
                        this.props.setIBCTxInProgress(true);
                        this.props.fetchIBCBalance(this.props.value && this.props.value.network && this.props.value.network.api_address,
                            this.props.ibcAddress);
                        this.props.fetchBalance(this.props.address);
                        this.props.setIBCTransactionReset();
                        const time = setInterval(() => {
                            this.props.fetchBalance(this.props.address, (resBalance) => {
                                let resultBalance = resBalance && resBalance.length && denom &&
                                    resBalance.find((val) => val.denom === denom);
                                resultBalance = resultBalance && resultBalance.amount;
                                if (resultBalance !== balance) {
                                    this.props.setIBCTxInProgress(false);
                                    this.props.showMessage('Transaction Successful', 'success', result && result.transactionHash, explorer);
                                    this.props.fetchBalance(this.props.address);
                                    clearInterval(time);
                                }
                            });
                        }, 5000);
                        this.props.handleClose();
                    }
                });

                return;
            }

            this.props.sign(this.state.config, data, (result) => {
                if (result) {
                    this.props.setIBCTxInProgress(true);
                    this.props.fetchIBCBalance(this.props.value && this.props.value.network && this.props.value.network.api_address,
                        this.props.ibcAddress);
                    this.props.fetchBalance(this.props.address);
                    this.props.setIBCTransactionReset();
                    const time = setInterval(() => {
                        this.props.fetchBalance(this.props.address, (resBalance) => {
                            let resultBalance = resBalance && resBalance.length && denom &&
                                resBalance.find((val) => val.denom === denom);
                            resultBalance = resultBalance && resultBalance.amount;
                            if (resultBalance !== balance) {
                                this.props.setIBCTxInProgress(false);
                                this.props.showMessage('Transaction Successful', 'success', result && result.transactionHash, explorer);
                                this.props.fetchBalance(this.props.address);
                                this.props.fetchIBCBalance(this.props.value && this.props.value.network && this.props.value.network.api_address,
                                    this.props.ibcAddress);
                                clearInterval(time);
                            }
                        });
                    }, 5000);
                    this.props.handleClose();
                }
            });
        });
    }

    render () {
        const decimals = this.props.value && this.props.value.network && this.props.value.network.decimals;

        let balance = this.props.balance && this.props.balance.length &&
            this.props.value && this.props.value.network && this.props.value.network.denom &&
            this.props.balance.find((val) => val.denom === this.props.value.network.denom);
        balance = balance && balance.amount && balance.amount / (10 ** decimals);

        const amountWithDecimals = this.props.amountValue && (this.props.amountValue * (10 ** decimals));
        const inProgress = this.props.accountInProgress || this.props.timeoutHeightInProgress ||
            this.props.signInProgress || this.props.inProgressSign;
        const broadCastInProgress = this.props.broadCastInProgress || this.props.inProgressTxHash;
        const disable = amountWithDecimals < 1 || this.props.amountValue > balance || inProgress || broadCastInProgress;
        const denom = this.props.value && this.props.value.network && this.props.value.network.display_denom;

        return (
            <Dialog
                aria-describedby="verify-twitter-dialog-description"
                aria-labelledby="verify-twitter-dialog-title"
                className="dialog deposite_dialog"
                open={this.props.open}
                onClose={this.props.handleClose}>
                <div className="confirm_ibc_dialog">
                    <h2>{variables[this.props.lang].confirm_ibc_deposit_details}</h2>
                    <div className="ledger_note">
                        <p>{variables[this.props.lang]['ledger_note']}</p>
                    </div>
                    <div>
                        <div className="row">
                            <span className="label">{variables[this.props.lang].message_type}</span>
                            <p className="value">/ibc.applications.transfer.v1.MsgTransfer</p>
                        </div>
                        <div className="row">
                            <span className="label">{variables[this.props.lang].selected_token}</span>
                            <p className="value token_info">
                                <NetworkImages alt={denom} name={denom}/>
                                {denom}</p>
                        </div>
                        <div className="row">
                            <span className="label">{variables[this.props.lang].amount_deposit}</span>
                            <p className="value">{this.props.amountValue}</p>
                        </div>
                        <div className="row">
                            <span className="label">{variables[this.props.lang].from}</span>
                            <div className="hash_text address" title={this.props.ibcAddress}>
                                <p className="name">{this.props.ibcAddress}</p>
                                {this.props.ibcAddress &&
                                    this.props.ibcAddress.slice(this.props.ibcAddress.length - 6, this.props.ibcAddress.length)}
                            </div>
                        </div>
                        <div className="row">
                            <span className="label">{variables[this.props.lang].to}</span>
                            <div className="hash_text address" title={this.props.address}>
                                <p className="name">{this.props.address}</p>
                                {this.props.address &&
                                    this.props.address.slice(this.props.address.length - 6, this.props.address.length)}
                            </div>

                        </div>
                    </div>
                    <div className="confirm_actions">
                        <Button
                            className="cancel_button"
                            onClick={this.props.handleClose}>
                            {variables[this.props.lang].cancel}
                        </Button>
                        <Button
                            className="deposite_button"
                            disabled={disable}
                            onClick={() => this.handleDeposit(decimals)}>
                            {broadCastInProgress
                                ? variables[this.props.lang].processing + '...'
                                : inProgress
                                    ? variables[this.props.lang]['approval_pending'] + '...'
                                    : variables[this.props.lang].confirm}
                        </Button>
                    </div>
                </div>
            </Dialog>
        );
    }
}

DepositDialog.propTypes = {
    accountInProgress: PropTypes.bool.isRequired,
    address: PropTypes.string.isRequired,
    allowances: PropTypes.array.isRequired,
    aminoSignIBCTxCosmoStation: PropTypes.func.isRequired,
    balance: PropTypes.array.isRequired,
    balanceInProgress: PropTypes.bool.isRequired,
    broadCastInProgress: PropTypes.bool.isRequired,
    connectIBCAccount: PropTypes.func.isRequired,
    fetchBalance: PropTypes.func.isRequired,
    fetchIBCBalance: PropTypes.func.isRequired,
    fetchTimeoutHeight: PropTypes.func.isRequired,
    fetchTxHash: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired,
    ibcAddress: PropTypes.string.isRequired,
    ibcTxInProgress: PropTypes.bool.isRequired,
    inProgress: PropTypes.bool.isRequired,
    inProgressSign: PropTypes.bool.isRequired,
    inProgressTxHash: PropTypes.bool.isRequired,
    keys: PropTypes.object.isRequired,
    lang: PropTypes.string.isRequired,
    mainBalance: PropTypes.array.isRequired,
    open: PropTypes.bool.isRequired,
    setIBCTransactionReset: PropTypes.func.isRequired,
    setIBCTxInProgress: PropTypes.func.isRequired,
    setTxHashInProgressFalse: PropTypes.func.isRequired,
    showMessage: PropTypes.func.isRequired,
    sign: PropTypes.func.isRequired,
    signInProgress: PropTypes.bool.isRequired,
    timeoutHeightInProgress: PropTypes.bool.isRequired,
    value: PropTypes.object.isRequired,
    amountValue: PropTypes.any,
};

const stateToProps = (state) => {
    return {
        address: state.account.wallet.connection.address,
        accountInProgress: state.account.wallet.connection.inProgress,
        allowances: state.account.bc.allowances.value,
        ibcAddress: state.account.ibc.connection.address,
        amountValue: state.navBar.amount.value,
        balance: state.account.ibc.balance.value,
        balanceInProgress: state.account.ibc.balance.inProgress,
        broadCastInProgress: state.account.wallet.broadCast.inProgress,
        inProgress: state.account.ibc.connection.inProgress,
        inProgressSign: state.account.wallet.signTx.inProgress,
        inProgressTxHash: state.account.bc.txHash.inProgress,
        signInProgress: state.account.ibc.connection.signInProgress,
        lang: state.language,
        mainBalance: state.account.bc.balance.value,
        open: state.account.ibc.depositeDialog.open,
        value: state.account.ibc.depositeDialog.value,
        timeoutHeightInProgress: state.account.ibc.timeoutHeight.inProgress,

        ibcTxInProgress: state.account.ibc.ibcTxInProgress,
        keys: state.account.wallet.connection.keys,
    };
};

const actionToProps = {
    connectIBCAccount,
    aminoSignIBCTxCosmoStation,
    fetchBalance,
    fetchIBCBalance,
    fetchTimeoutHeight,
    fetchTxHash,
    handleClose: hideDepositeDialog,
    sign: aminoSignIBCTx,
    showMessage,
    setIBCTxInProgress,
    setTxHashInProgressFalse,
    setIBCTransactionReset,
};

export default connect(stateToProps, actionToProps)(DepositDialog);
