import { combineReducers } from 'redux';
import {
    CONNECT_DIALOG_HIDE,
    CONNECT_DIALOG_SHOW,
    DEPOSIT_ADDRESS_VALUE_SET,
    DEPOSIT_AMOUNT_VALUE_SET,
    DEPOSIT_TOKEN_VALUE_SET,
    RESET_IBC_TRANSACTION_SET,
    SIDE_BAR_DRAWER_HIDE,
    SIDE_BAR_DRAWER_SHOW,
    TRANSACTION_TYPE_SET,
} from '../constants/navBar';

const sideBarDrawer = (state = {
    open: false,
}, action) => {
    switch (action.type) {
    case SIDE_BAR_DRAWER_SHOW:
        return {
            open: true,
        };
    case SIDE_BAR_DRAWER_HIDE:
        return {
            open: false,
        };

    default:
        return state;
    }
};

const token = (state = {
    value: {},
}, action) => {
    switch (action.type) {
    case DEPOSIT_TOKEN_VALUE_SET:
        return {
            ...state,
            value: action.value,
        };

    default:
        return state;
    }
};

const amount = (state = {
    value: '',
}, action) => {
    switch (action.type) {
    case DEPOSIT_AMOUNT_VALUE_SET:
        return {
            ...state,
            value: action.value,
        };
    case RESET_IBC_TRANSACTION_SET:
        return {
            value: '',
        };

    default:
        return state;
    }
};

const address = (state = {
    value: '',
}, action) => {
    switch (action.type) {
    case DEPOSIT_ADDRESS_VALUE_SET:
        return {
            ...state,
            value: action.value,
        };
    case RESET_IBC_TRANSACTION_SET:
        return {
            value: '',
        };

    default:
        return state;
    }
};

const transactionType = (state = {
    value: 'deposit',
}, action) => {
    switch (action.type) {
    case TRANSACTION_TYPE_SET:
        return {
            ...state,
            value: action.value,
        };
    case RESET_IBC_TRANSACTION_SET:
        return {
            value: 'deposit',
        };

    default:
        return state;
    }
};

const connectDialog = (state = false, action) => {
    switch (action.type) {
    case CONNECT_DIALOG_SHOW:
        return true;
    case CONNECT_DIALOG_HIDE:
        return false;
    default:
        return state;
    }
};

export default combineReducers({
    sideBarDrawer,
    token,
    amount,
    address,
    transactionType,
    connectDialog,
});
