import {
    ACTIVE_STREAMS_ERROR,
    ACTIVE_STREAMS_IN_PROGRESS,
    ACTIVE_STREAMS_SUCCESS,
    ALL_STREAMS_ERROR,
    ALL_STREAMS_IN_PROGRESS,
    ALL_STREAMS_SUCCESS,
    COMPLETED_STREAMS_ERROR,
    COMPLETED_STREAMS_IN_PROGRESS,
    COMPLETED_STREAMS_SUCCESS,
    FETCH_STREAM_INFO_ERROR,
    FETCH_STREAM_INFO_IN_PROGRESS,
    FETCH_STREAM_INFO_SUCCESS,
    FETCH_STREAMS_STATS_ERROR,
    FETCH_STREAMS_STATS_IN_PROGRESS,
    FETCH_STREAMS_STATS_SUCCESS,
    FILTER_DIALOG_HIDE,
    FILTER_DIALOG_SHOW,
    SEARCH_STREAMS_SET,
    STOPPED_STREAMS_ERROR,
    STOPPED_STREAMS_IN_PROGRESS,
    STOPPED_STREAMS_SUCCESS, STREAMS_FILTER_VALUE_SET,
    TAB_STREAMS_SET,
} from '../constants/streams';
import { urlStreamInfo, urlStreams, urlStreamsStats } from '../constants/url';
import Axios from 'axios';

export const setStreamsFilterValue = (value) => {
    return {
        type: STREAMS_FILTER_VALUE_SET,
        value,
    };
};

export const setSearchStreams = (value) => {
    return {
        type: SEARCH_STREAMS_SET,
        value,
    };
};

export const setTabStreams = (value) => {
    return {
        type: TAB_STREAMS_SET,
        value,
    };
};

export const showFilterDialog = (value) => {
    return {
        type: FILTER_DIALOG_SHOW,
        value,
    };
};

export const hideFilterDialog = () => {
    return {
        type: FILTER_DIALOG_HIDE,
    };
};

const fetchAllStreamsInProgress = () => {
    return {
        type: ALL_STREAMS_IN_PROGRESS,
    };
};

const fetchAllStreamsSuccess = (value, count, skip, limit) => {
    return {
        type: ALL_STREAMS_SUCCESS,
        value,
        count,
        skip,
        limit,
    };
};

const fetchAllStreamsError = (message) => {
    return {
        type: ALL_STREAMS_ERROR,
        message,
        variant: 'error',
    };
};

export const fetchAllStreams = (address, skip, limit, obj, sortBy, order, cb) => (dispatch) => {
    dispatch(fetchAllStreamsInProgress());
    const url = urlStreams(address, '', skip, limit, obj, sortBy, order);
    Axios.get(url, {
        headers: {
            Accept: 'application/json, text/plain, */*',
        },
    })
        .then((res) => {
            dispatch(fetchAllStreamsSuccess(res && res.data && res.data.result && res.data.result.list,
                res && res.data && res.data.result && res.data.result.count, skip, limit));
            if (cb) {
                cb(res && res.data && res.data.result && res.data.result.list,
                    res && res.data && res.data.result && res.data.result.count);
            }
        })
        .catch((error) => {
            dispatch(fetchAllStreamsError(
                error.response &&
                error.response.data &&
                error.response.data.error
                    ? error.response.data.error
                    : error.response &&
                    error.response.data &&
                    error.response.data.message
                        ? error.response.data.message
                        : 'Failed!',
            ));
            if (cb) {
                cb(null);
            }
        });
};

const fetchActiveStreamsInProgress = () => {
    return {
        type: ACTIVE_STREAMS_IN_PROGRESS,
    };
};

const fetchActiveStreamsSuccess = (value, count, skip, limit) => {
    return {
        type: ACTIVE_STREAMS_SUCCESS,
        value,
        count,
        skip,
        limit,
    };
};

const fetchActiveStreamsError = (message) => {
    return {
        type: ACTIVE_STREAMS_ERROR,
        message,
        variant: 'error',
    };
};

export const fetchActiveStreams = (address, skip, limit, cb) => (dispatch) => {
    dispatch(fetchActiveStreamsInProgress());
    const url = urlStreams(address, 'ongoing', skip, limit);
    Axios.get(url, {
        headers: {
            Accept: 'application/json, text/plain, */*',
        },
    })
        .then((res) => {
            dispatch(fetchActiveStreamsSuccess(res && res.data && res.data.result && res.data.result.list,
                res && res.data && res.data.result && res.data.result.count, skip, limit));
            if (cb) {
                cb(res && res.data && res.data.result && res.data.result.list,
                    res && res.data && res.data.result && res.data.result.count);
            }
        })
        .catch((error) => {
            dispatch(fetchActiveStreamsError(
                error.response &&
                error.response.data &&
                error.response.data.error
                    ? error.response.data.error
                    : error.response &&
                    error.response.data &&
                    error.response.data.message
                        ? error.response.data.message
                        : 'Failed!',
            ));
            if (cb) {
                cb(null);
            }
        });
};

const fetchCompletedStreamsInProgress = () => {
    return {
        type: COMPLETED_STREAMS_IN_PROGRESS,
    };
};

const fetchCompletedStreamsSuccess = (value, count, skip, limit) => {
    return {
        type: COMPLETED_STREAMS_SUCCESS,
        value,
        count,
        skip,
        limit,
    };
};

const fetchCompletedStreamsError = (message) => {
    return {
        type: COMPLETED_STREAMS_ERROR,
        message,
        variant: 'error',
    };
};

export const fetchCompletedStreams = (address, skip, limit, cb) => (dispatch) => {
    dispatch(fetchCompletedStreamsInProgress());
    const url = urlStreams(address, 'STREAM_PAYMENT_STATUS_COMPLETED', skip, limit);
    Axios.get(url, {
        headers: {
            Accept: 'application/json, text/plain, */*',
        },
    })
        .then((res) => {
            dispatch(fetchCompletedStreamsSuccess(res && res.data && res.data.result && res.data.result.list,
                res && res.data && res.data.result && res.data.result.count, skip, limit));
            if (cb) {
                cb(res && res.data && res.data.result && res.data.result.list,
                    res && res.data && res.data.result && res.data.result.count);
            }
        })
        .catch((error) => {
            dispatch(fetchCompletedStreamsError(
                error.response &&
                error.response.data &&
                error.response.data.error
                    ? error.response.data.error
                    : error.response &&
                    error.response.data &&
                    error.response.data.message
                        ? error.response.data.message
                        : 'Failed!',
            ));
            if (cb) {
                cb(null);
            }
        });
};

const fetchStoppedStreamsInProgress = () => {
    return {
        type: STOPPED_STREAMS_IN_PROGRESS,
    };
};

const fetchStoppedStreamsSuccess = (value, count, skip, limit) => {
    return {
        type: STOPPED_STREAMS_SUCCESS,
        value,
        count,
        skip,
        limit,
    };
};

const fetchStoppedStreamsError = (message) => {
    return {
        type: STOPPED_STREAMS_ERROR,
        message,
        variant: 'error',
    };
};

export const fetchStoppedStreams = (address, skip, limit, cb) => (dispatch) => {
    dispatch(fetchStoppedStreamsInProgress());
    const url = urlStreams(address, 'STREAM_PAYMENT_STATUS_CANCELLED', skip, limit);
    Axios.get(url, {
        headers: {
            Accept: 'application/json, text/plain, */*',
        },
    })
        .then((res) => {
            dispatch(fetchStoppedStreamsSuccess(res && res.data && res.data.result && res.data.result.list,
                res && res.data && res.data.result && res.data.result.count, skip, limit));
            if (cb) {
                cb(res && res.data && res.data.result && res.data.result.list,
                    res && res.data && res.data.result && res.data.result.count);
            }
        })
        .catch((error) => {
            dispatch(fetchStoppedStreamsError(
                error.response &&
                error.response.data &&
                error.response.data.error
                    ? error.response.data.error
                    : error.response &&
                    error.response.data &&
                    error.response.data.message
                        ? error.response.data.message
                        : 'Failed!',
            ));
            if (cb) {
                cb(null);
            }
        });
};

const fetchStreamInfoInProgress = () => {
    return {
        type: FETCH_STREAM_INFO_IN_PROGRESS,
    };
};

const fetchStreamInfoSuccess = (value) => {
    return {
        type: FETCH_STREAM_INFO_SUCCESS,
        value,
        variant: 'success',
    };
};

const fetchStreamInfoError = (message) => {
    return {
        type: FETCH_STREAM_INFO_ERROR,
        message,
        variant: 'error',
    };
};

export const fetchStreamInfo = (id, cb) => (dispatch) => {
    dispatch(fetchStreamInfoInProgress());

    const url = urlStreamInfo(id);
    Axios.get(url, {
        headers: {
            Accept: 'application/json, text/plain, */*',
        },
    })
        .then((res) => {
            dispatch(fetchStreamInfoSuccess(res.data && res.data.result));
            if (cb) {
                cb(res.data && res.data.result);
            }
        })
        .catch((error) => {
            dispatch(fetchStreamInfoError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
            if (cb) {
                cb(null);
            }
        });
};

const fetchStreamsStatsInProgress = () => {
    return {
        type: FETCH_STREAMS_STATS_IN_PROGRESS,
    };
};

const fetchStreamsStatsSuccess = (value) => {
    return {
        type: FETCH_STREAMS_STATS_SUCCESS,
        value,
        variant: 'success',
    };
};

const fetchStreamsStatsError = (message) => {
    return {
        type: FETCH_STREAMS_STATS_ERROR,
        message,
        variant: 'error',
    };
};

export const fetchStreamsStats = (id, cb) => (dispatch) => {
    dispatch(fetchStreamsStatsInProgress());

    const url = urlStreamsStats(id);
    Axios.get(url, {
        headers: {
            Accept: 'application/json, text/plain, */*',
        },
    })
        .then((res) => {
            dispatch(fetchStreamsStatsSuccess(res.data && res.data.result));
            if (cb) {
                cb(res.data && res.data.result);
            }
        })
        .catch((error) => {
            dispatch(fetchStreamsStatsError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
            if (cb) {
                cb(null);
            }
        });
};
