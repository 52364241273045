import { config } from '../config';

const variables = {
    en: {
        connect: 'Connect',
        connect_with: 'Connect With',
        connect_with_info: 'Introducing StreamPay, the next-gen payment module on the OmniFlix Network, offering seamless streaming of tokens over time whether it\'s regular payouts, real-time micro transactions, or lump-sum transfers. Transform your transaction experience with StreamPay.',
        continuous_stream: 'Continuous Stream',
        continue_stream_content: 'Stream tokens every second. This real-time microtransaction feature revolutionizes immediate payments and value transfers.',
        delayed_stream: 'Delayed Stream',
        delayed_stream_content: 'Stream tokens at a specific end time. Perfect for deferred payments, releasing the tokens upon completion of a task',
        security: 'Security',
        security_content: 'Enjoy safe and trustless transactions with the robust and secure infrastructure on OmniFlix Network',
        periodic_stream: 'Periodic Stream',
        periodic_stream_content: 'Stream tokens at regular intervals. Perfect for predictable, routine payouts such as salaries or subscription-based payments',
        supports_ibc: 'Supports IBC',
        supports_ibc_content: 'Supports 10+ tokens within Cosmos ecosystem, making StreamPay versatile across multiple blockchain platforms.',
        easy_integration: 'Easy Integration',
        easy_integration_content: 'The intuitive user interface and user-friendly API offer seamless integration and a straightforward user experience.',

        //     Create
        start_creating_payment: 'Start Creating Payment Stream',
        to: 'To',
        type: 'Type',
        address_placeholder: 'Enter account address',
        continuous: 'Continuous',
        continuous_content: 'Stream tokens every second till end time.',
        delayed: 'Delayed',
        delayed_content: 'Stream total tokens at end time.',
        periodic: 'Periodic',
        periodic_content: 'Stream total tokens per epoch till end time',
        ends_at: 'Ends at (local time)',
        number_of_periods: 'No.of Periods',
        enter_periods: 'Enter Periods',
        intervals: 'Intervals',
        enter_intervals: 'Enter Intervals',

        tokens_to_transfer: 'Tokens to Transfer',
        enter_amount: 'Enter amount',
        available: 'Available',
        max: 'Max',
        half: 'Half',
        end_time: 'End Time',
        stoppable_stream: 'Stoppable stream',
        creation_fee: 'Creation Fee',
        create_payment_stream: 'Create Payment Stream',
        connect_with_us: 'CONNECT WITH US',
        end_date_placeholder: 'dd mmm yyyy hh:mm:ss ',
        enter_end_time: 'enter the end time in your local time',
        payment_stream: 'Payment Stream',
        stream_pay_fee: 'StreamPay Fee',
        total: 'Total',

        // Streams Header
        all: 'All',
        active: 'Active',
        completed: 'Completed',
        stopped: 'Stopped',
        search_placeholder: 'Search by ID/account/amount',
        disconnect: 'Disconnect',
        claim_all: 'Claim All',
        do_you_want: 'Do you want to ',
        your_tokens_from: ' your tokens from multiple streams?',
        yes: 'Yes',
        no: 'No',
        claim_successful: 'Claim Successful!',
        tx_hash: 'Tx Hash',
        tweet: 'Tweet',
        view_tokens: 'View Tokens',
        rewards_claimed: 'Rewards Claimed',

        streamed: 'streamed',
        incoming: 'incoming',
        outgoing: 'outgoing',
        stop: 'Stop',
        from: 'From',
        claimable_in: 'Claimable in',
        claim: 'Claim',
        stream_ends_in: 'Stream ends in',
        stream_starts_in: 'Stream starts in',
        next_stream_in: 'Next stream in',
        last_streamed: 'Last streamed',
        stream_stopped: 'Stream stopped',
        load_more: 'Load More...',
        approval_pending: 'Approval Pending',
        processing: 'Processing',

        //  single stream
        back: 'back',
        share_link: 'share link',
        token_streamed: 'Tokens Streamed',
        stop_stream: 'Stop Stream',
        claim_tokens: 'Claim Tokens',
        claimed: 'Claimed',
        stream_initiated: 'Stream initiated',
        tokens_to_stream: 'Tokens to stream',
        stream_type: 'Stream Type',
        stream_id: 'Stream ID',
        check_later: 'Transaction is in process. check after some time.',
        claimed_tokens: 'Claimed Tokens',
        claimable_tokens: 'Claimable Tokens',
        cancellable_stream: 'Cancelled Stream',
        completed_stream: 'Completed Stream',

        // Account bar
        active_account: 'Active Account',
        claim_test_tokens: 'Claim Test Tokens',
        my_account: 'My Account',
        my_streams: 'My Streams',
        tokens: 'Tokens',
        activity: 'Activity',
        ibc_deposit_withdraw: 'IBC Deposit & Withdraw',
        amount_to_deposit: 'Amount to Deposit',
        deposit: 'Deposit',
        withdraw: 'Withdraw',
        transaction_type: 'Transaction Type',

        confirm_ibc_deposit_details: 'Confirm IBC Deposit Details',
        confirm_ibc_withdraw_details: 'Confirm IBC Withdraw Details',
        message_type: 'Message Type',
        selected_token: 'Selected Token',
        amount_deposit: 'Amount to Deposit',
        confirm: 'Confirm',
        cancel: 'Cancel',
        mint_scan: 'Mintscan',

        // connect dialog
        cosmostation: 'Cosmostation',
        leap: 'Leap',
        connect_wallet: 'Connect Your Wallet',
        keplr_wallet: 'Keplr Wallet',
        wallet_connect: 'Wallet Connect',
        chain_not_supported: `${config.CHAIN_ID} are not currently supported chain`,
        connecting: 'Connecting',
        ibc_deprecated_text: 'will soon be deprecated. Do withdraw your funds and De-list your NFTs from',
        filters: 'Filters',
        clear: 'Clear',
        streaming_phase: 'Streaming Phase',
        status: 'Status',
        statuses: 'Statuses',
        upcoming: 'Upcoming',
        ongoing: 'Ongoing',

        flixdrop_tweet_text: 'Cosmonauts! Claimed my #FlixDrop 🔥\n\n',
        flixdrop_tweet_text1: 'Claim your $FLIX to enter a world of web3 media on OmniFlix.market to: \n\n',
        flixdrop_tweet_text2: '✅ Create NFT collections\n',
        flixdrop_tweet_text3: '✅ List NFTs for fixed-price or auctions\n',
        flixdrop_tweet_text4: '✅ List in any IBC token\n',
        flixdrop_tweet_text5: '✅ Create NFT campaigns to engage your community\n',
    },
};

export default variables;
