/* eslint-disable */
import * as _m0 from "protobufjs/minimal";
import { Coin } from "../../cosmos/base/v1beta1/coin";
import { Duration } from "../../google/protobuf/duration";
import { Params } from "./params";
import { Period, StreamType, streamTypeFromJSON, streamTypeToJSON } from "./streampay";

export const protobufPackage = "OmniFlix.streampay.v1";

export interface MsgStreamSend {
  sender: string;
  recipient: string;
  amount: Coin | undefined;
  duration: Duration | undefined;
  streamType: StreamType;
  periods: Period[];
  cancellable: boolean;
  paymentFee: Coin | undefined;
}

export interface MsgStreamSendResponse {
  streamId: string;
}

export interface MsgStopStream {
  streamId: string;
  sender: string;
}

export interface MsgStopStreamResponse {
}

export interface MsgClaimStreamedAmount {
  streamId: string;
  claimer: string;
}

export interface MsgClaimStreamedAmountResponse {
}

/**
 * MsgUpdateParams is the Msg/UpdateParams request type.
 *
 * Since: cosmos-sdk 0.47
 */
export interface MsgUpdateParams {
  /** authority is the address of the governance account. */
  authority: string;
  /**
   * params defines the x/streampay parameters to update.
   *
   * NOTE: All parameters must be supplied.
   */
  params: Params | undefined;
}

/**
 * MsgUpdateParamsResponse defines the response structure for executing a
 * MsgUpdateParams message.
 *
 * Since: cosmos-sdk 0.47
 */
export interface MsgUpdateParamsResponse {
}

function createBaseMsgStreamSend(): MsgStreamSend {
  return {
    sender: "",
    recipient: "",
    amount: undefined,
    duration: undefined,
    streamType: 0,
    periods: [],
    cancellable: false,
    paymentFee: undefined,
  };
}

export const MsgStreamSend = {
  encode(message: MsgStreamSend, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.sender !== "") {
      writer.uint32(10).string(message.sender);
    }
    if (message.recipient !== "") {
      writer.uint32(18).string(message.recipient);
    }
    if (message.amount !== undefined) {
      Coin.encode(message.amount, writer.uint32(26).fork()).ldelim();
    }
    if (message.duration !== undefined) {
      Duration.encode(message.duration, writer.uint32(34).fork()).ldelim();
    }
    if (message.streamType !== 0) {
      writer.uint32(40).int32(message.streamType);
    }
    for (const v of message.periods) {
      Period.encode(v!, writer.uint32(50).fork()).ldelim();
    }
    if (message.cancellable === true) {
      writer.uint32(56).bool(message.cancellable);
    }
    if (message.paymentFee !== undefined) {
      Coin.encode(message.paymentFee, writer.uint32(66).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MsgStreamSend {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMsgStreamSend();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.sender = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.recipient = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.amount = Coin.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.duration = Duration.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.streamType = reader.int32() as any;
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.periods.push(Period.decode(reader, reader.uint32()));
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.cancellable = reader.bool();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.paymentFee = Coin.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MsgStreamSend {
    return {
      sender: isSet(object.sender) ? globalThis.String(object.sender) : "",
      recipient: isSet(object.recipient) ? globalThis.String(object.recipient) : "",
      amount: isSet(object.amount) ? Coin.fromJSON(object.amount) : undefined,
      duration: isSet(object.duration) ? Duration.fromJSON(object.duration) : undefined,
      streamType: isSet(object.streamType) ? streamTypeFromJSON(object.streamType) : 0,
      periods: globalThis.Array.isArray(object?.periods) ? object.periods.map((e: any) => Period.fromJSON(e)) : [],
      cancellable: isSet(object.cancellable) ? globalThis.Boolean(object.cancellable) : false,
      paymentFee: isSet(object.paymentFee) ? Coin.fromJSON(object.paymentFee) : undefined,
    };
  },

  toJSON(message: MsgStreamSend): unknown {
    const obj: any = {};
    if (message.sender !== "") {
      obj.sender = message.sender;
    }
    if (message.recipient !== "") {
      obj.recipient = message.recipient;
    }
    if (message.amount !== undefined) {
      obj.amount = Coin.toJSON(message.amount);
    }
    if (message.duration !== undefined) {
      obj.duration = Duration.toJSON(message.duration);
    }
    if (message.streamType !== 0) {
      obj.streamType = streamTypeToJSON(message.streamType);
    }
    if (message.periods?.length) {
      obj.periods = message.periods.map((e) => Period.toJSON(e));
    }
    if (message.cancellable === true) {
      obj.cancellable = message.cancellable;
    }
    if (message.paymentFee !== undefined) {
      obj.paymentFee = Coin.toJSON(message.paymentFee);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MsgStreamSend>, I>>(base?: I): MsgStreamSend {
    return MsgStreamSend.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<MsgStreamSend>, I>>(object: I): MsgStreamSend {
    const message = createBaseMsgStreamSend();
    message.sender = object.sender ?? "";
    message.recipient = object.recipient ?? "";
    message.amount = (object.amount !== undefined && object.amount !== null)
      ? Coin.fromPartial(object.amount)
      : undefined;
    message.duration = (object.duration !== undefined && object.duration !== null)
      ? Duration.fromPartial(object.duration)
      : undefined;
    message.streamType = object.streamType ?? 0;
    message.periods = object.periods?.map((e) => Period.fromPartial(e)) || [];
    message.cancellable = object.cancellable ?? false;
    message.paymentFee = (object.paymentFee !== undefined && object.paymentFee !== null)
      ? Coin.fromPartial(object.paymentFee)
      : undefined;
    return message;
  },
};

function createBaseMsgStreamSendResponse(): MsgStreamSendResponse {
  return { streamId: "" };
}

export const MsgStreamSendResponse = {
  encode(message: MsgStreamSendResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.streamId !== "") {
      writer.uint32(10).string(message.streamId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MsgStreamSendResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMsgStreamSendResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.streamId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MsgStreamSendResponse {
    return { streamId: isSet(object.streamId) ? globalThis.String(object.streamId) : "" };
  },

  toJSON(message: MsgStreamSendResponse): unknown {
    const obj: any = {};
    if (message.streamId !== "") {
      obj.streamId = message.streamId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MsgStreamSendResponse>, I>>(base?: I): MsgStreamSendResponse {
    return MsgStreamSendResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<MsgStreamSendResponse>, I>>(object: I): MsgStreamSendResponse {
    const message = createBaseMsgStreamSendResponse();
    message.streamId = object.streamId ?? "";
    return message;
  },
};

function createBaseMsgStopStream(): MsgStopStream {
  return { streamId: "", sender: "" };
}

export const MsgStopStream = {
  encode(message: MsgStopStream, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.streamId !== "") {
      writer.uint32(10).string(message.streamId);
    }
    if (message.sender !== "") {
      writer.uint32(18).string(message.sender);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MsgStopStream {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMsgStopStream();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.streamId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.sender = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MsgStopStream {
    return {
      streamId: isSet(object.streamId) ? globalThis.String(object.streamId) : "",
      sender: isSet(object.sender) ? globalThis.String(object.sender) : "",
    };
  },

  toJSON(message: MsgStopStream): unknown {
    const obj: any = {};
    if (message.streamId !== "") {
      obj.streamId = message.streamId;
    }
    if (message.sender !== "") {
      obj.sender = message.sender;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MsgStopStream>, I>>(base?: I): MsgStopStream {
    return MsgStopStream.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<MsgStopStream>, I>>(object: I): MsgStopStream {
    const message = createBaseMsgStopStream();
    message.streamId = object.streamId ?? "";
    message.sender = object.sender ?? "";
    return message;
  },
};

function createBaseMsgStopStreamResponse(): MsgStopStreamResponse {
  return {};
}

export const MsgStopStreamResponse = {
  encode(_: MsgStopStreamResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MsgStopStreamResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMsgStopStreamResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): MsgStopStreamResponse {
    return {};
  },

  toJSON(_: MsgStopStreamResponse): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<MsgStopStreamResponse>, I>>(base?: I): MsgStopStreamResponse {
    return MsgStopStreamResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<MsgStopStreamResponse>, I>>(_: I): MsgStopStreamResponse {
    const message = createBaseMsgStopStreamResponse();
    return message;
  },
};

function createBaseMsgClaimStreamedAmount(): MsgClaimStreamedAmount {
  return { streamId: "", claimer: "" };
}

export const MsgClaimStreamedAmount = {
  encode(message: MsgClaimStreamedAmount, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.streamId !== "") {
      writer.uint32(10).string(message.streamId);
    }
    if (message.claimer !== "") {
      writer.uint32(18).string(message.claimer);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MsgClaimStreamedAmount {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMsgClaimStreamedAmount();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.streamId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.claimer = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MsgClaimStreamedAmount {
    return {
      streamId: isSet(object.streamId) ? globalThis.String(object.streamId) : "",
      claimer: isSet(object.claimer) ? globalThis.String(object.claimer) : "",
    };
  },

  toJSON(message: MsgClaimStreamedAmount): unknown {
    const obj: any = {};
    if (message.streamId !== "") {
      obj.streamId = message.streamId;
    }
    if (message.claimer !== "") {
      obj.claimer = message.claimer;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MsgClaimStreamedAmount>, I>>(base?: I): MsgClaimStreamedAmount {
    return MsgClaimStreamedAmount.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<MsgClaimStreamedAmount>, I>>(object: I): MsgClaimStreamedAmount {
    const message = createBaseMsgClaimStreamedAmount();
    message.streamId = object.streamId ?? "";
    message.claimer = object.claimer ?? "";
    return message;
  },
};

function createBaseMsgClaimStreamedAmountResponse(): MsgClaimStreamedAmountResponse {
  return {};
}

export const MsgClaimStreamedAmountResponse = {
  encode(_: MsgClaimStreamedAmountResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MsgClaimStreamedAmountResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMsgClaimStreamedAmountResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): MsgClaimStreamedAmountResponse {
    return {};
  },

  toJSON(_: MsgClaimStreamedAmountResponse): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<MsgClaimStreamedAmountResponse>, I>>(base?: I): MsgClaimStreamedAmountResponse {
    return MsgClaimStreamedAmountResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<MsgClaimStreamedAmountResponse>, I>>(_: I): MsgClaimStreamedAmountResponse {
    const message = createBaseMsgClaimStreamedAmountResponse();
    return message;
  },
};

function createBaseMsgUpdateParams(): MsgUpdateParams {
  return { authority: "", params: undefined };
}

export const MsgUpdateParams = {
  encode(message: MsgUpdateParams, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.authority !== "") {
      writer.uint32(10).string(message.authority);
    }
    if (message.params !== undefined) {
      Params.encode(message.params, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MsgUpdateParams {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMsgUpdateParams();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.authority = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.params = Params.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MsgUpdateParams {
    return {
      authority: isSet(object.authority) ? globalThis.String(object.authority) : "",
      params: isSet(object.params) ? Params.fromJSON(object.params) : undefined,
    };
  },

  toJSON(message: MsgUpdateParams): unknown {
    const obj: any = {};
    if (message.authority !== "") {
      obj.authority = message.authority;
    }
    if (message.params !== undefined) {
      obj.params = Params.toJSON(message.params);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MsgUpdateParams>, I>>(base?: I): MsgUpdateParams {
    return MsgUpdateParams.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<MsgUpdateParams>, I>>(object: I): MsgUpdateParams {
    const message = createBaseMsgUpdateParams();
    message.authority = object.authority ?? "";
    message.params = (object.params !== undefined && object.params !== null)
      ? Params.fromPartial(object.params)
      : undefined;
    return message;
  },
};

function createBaseMsgUpdateParamsResponse(): MsgUpdateParamsResponse {
  return {};
}

export const MsgUpdateParamsResponse = {
  encode(_: MsgUpdateParamsResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MsgUpdateParamsResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMsgUpdateParamsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): MsgUpdateParamsResponse {
    return {};
  },

  toJSON(_: MsgUpdateParamsResponse): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<MsgUpdateParamsResponse>, I>>(base?: I): MsgUpdateParamsResponse {
    return MsgUpdateParamsResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<MsgUpdateParamsResponse>, I>>(_: I): MsgUpdateParamsResponse {
    const message = createBaseMsgUpdateParamsResponse();
    return message;
  },
};

/** Msg defines the Msg service. */
export interface Msg {
  StreamSend(request: MsgStreamSend): Promise<MsgStreamSendResponse>;
  StopStream(request: MsgStopStream): Promise<MsgStopStreamResponse>;
  ClaimStreamedAmount(request: MsgClaimStreamedAmount): Promise<MsgClaimStreamedAmountResponse>;
  /**
   * UpdateParams defines a governance operation for updating the x/streampay module
   * parameters. The authority is hard-coded to the x/gov module account.
   *
   * Since: cosmos-sdk 0.47
   */
  UpdateParams(request: MsgUpdateParams): Promise<MsgUpdateParamsResponse>;
}

export const MsgServiceName = "OmniFlix.streampay.v1.Msg";
export class MsgClientImpl implements Msg {
  private readonly rpc: Rpc;
  private readonly service: string;
  constructor(rpc: Rpc, opts?: { service?: string }) {
    this.service = opts?.service || MsgServiceName;
    this.rpc = rpc;
    this.StreamSend = this.StreamSend.bind(this);
    this.StopStream = this.StopStream.bind(this);
    this.ClaimStreamedAmount = this.ClaimStreamedAmount.bind(this);
    this.UpdateParams = this.UpdateParams.bind(this);
  }
  StreamSend(request: MsgStreamSend): Promise<MsgStreamSendResponse> {
    const data = MsgStreamSend.encode(request).finish();
    const promise = this.rpc.request(this.service, "StreamSend", data);
    return promise.then((data) => MsgStreamSendResponse.decode(_m0.Reader.create(data)));
  }

  StopStream(request: MsgStopStream): Promise<MsgStopStreamResponse> {
    const data = MsgStopStream.encode(request).finish();
    const promise = this.rpc.request(this.service, "StopStream", data);
    return promise.then((data) => MsgStopStreamResponse.decode(_m0.Reader.create(data)));
  }

  ClaimStreamedAmount(request: MsgClaimStreamedAmount): Promise<MsgClaimStreamedAmountResponse> {
    const data = MsgClaimStreamedAmount.encode(request).finish();
    const promise = this.rpc.request(this.service, "ClaimStreamedAmount", data);
    return promise.then((data) => MsgClaimStreamedAmountResponse.decode(_m0.Reader.create(data)));
  }

  UpdateParams(request: MsgUpdateParams): Promise<MsgUpdateParamsResponse> {
    const data = MsgUpdateParams.encode(request).finish();
    const promise = this.rpc.request(this.service, "UpdateParams", data);
    return promise.then((data) => MsgUpdateParamsResponse.decode(_m0.Reader.create(data)));
  }
}

interface Rpc {
  request(service: string, method: string, data: Uint8Array): Promise<Uint8Array>;
}

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
