import { combineReducers } from 'redux';
import language from './language';
import snackbar from './snackbar';
import account from './account';
import create from './create';
import streams from './streams';
import params from './params';
import claims from './claims';
import navBar from './navBar';

export default combineReducers({
    account,
    language,
    snackbar,
    create,
    streams,
    params,
    claims,
    navBar,
});
