import { combineReducers } from 'redux';
import {
    CONNECT_COSMO_STATION_ERROR,
    CONNECT_COSMO_STATION_IN_PROGRESS,
    CONNECT_COSMO_STATION_SUCCESS,
    CONNECT_KEPLR_ACCOUNT_ERROR,
    CONNECT_KEPLR_ACCOUNT_IN_PROGRESS,
    CONNECT_KEPLR_ACCOUNT_SUCCESS,
    CONNECT_LEAP_ACCOUNT_ERROR,
    CONNECT_LEAP_ACCOUNT_IN_PROGRESS,
    CONNECT_LEAP_ACCOUNT_SUCCESS,
    DISCONNECT_SET,
    KEPLR_ACCOUNT_KEYS_SET,
    TX_SIGN_AND_BROAD_CAST_ERROR,
    TX_SIGN_AND_BROAD_CAST_IN_PROGRESS,
    TX_SIGN_AND_BROAD_CAST_SUCCESS,
    TX_SIGN_ERROR,
    TX_SIGN_IN_PROGRESS,
    TX_SIGN_SUCCESS,
} from '../../constants/wallet';
import {
    CONNECT_BC_ACCOUNT_ERROR,
    CONNECT_BC_ACCOUNT_IN_PROGRESS,
    CONNECT_BC_ACCOUNT_SUCCESS,
    VERIFY_ACCOUNT_ERROR,
    VERIFY_ACCOUNT_IN_PROGRESS,
    VERIFY_ACCOUNT_SUCCESS,
} from '../../constants/account';

const connection = (state = {
    inProgress: false,
    address: '',
    addressInProgress: false,
    keys: {},
}, action) => {
    switch (action.type) {
    case CONNECT_KEPLR_ACCOUNT_IN_PROGRESS:
    case CONNECT_COSMO_STATION_IN_PROGRESS:
    case CONNECT_LEAP_ACCOUNT_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
            addressInProgress: true,
        };
    case CONNECT_BC_ACCOUNT_IN_PROGRESS:
    case VERIFY_ACCOUNT_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };

    case CONNECT_KEPLR_ACCOUNT_SUCCESS:
        return {
            ...state,
            addressInProgress: false,
            inProgress: false,
            address: action.value && action.value.length &&
            action.value[0] && action.value[0].address ? action.value[0].address : '',
        };
    case CONNECT_COSMO_STATION_SUCCESS:
        return {
            ...state,
            addressInProgress: false,
            inProgress: false,
            address: action.value && action.value.address,
            keys: action.value && action.value.address ? {
                ...action.value,
                bech32Address: action.value.address,
            } : {},
        };
    case CONNECT_LEAP_ACCOUNT_SUCCESS:
        return {
            ...state,
            addressInProgress: false,
            inProgress: false,
            address: action.value && action.value.length &&
            action.value[0] && action.value[0].address ? action.value[0].address : '',
        };
    case CONNECT_KEPLR_ACCOUNT_ERROR:
    case CONNECT_COSMO_STATION_ERROR:
    case CONNECT_LEAP_ACCOUNT_ERROR:
        return {
            ...state,
            inProgress: false,
            addressInProgress: false,
        };
    case CONNECT_BC_ACCOUNT_SUCCESS:
    case CONNECT_BC_ACCOUNT_ERROR:
    case VERIFY_ACCOUNT_ERROR:
    case VERIFY_ACCOUNT_SUCCESS:
        return {
            ...state,
            inProgress: false,
        };

    case KEPLR_ACCOUNT_KEYS_SET:
        return {
            ...state,
            keys: action.value,
        };
    case DISCONNECT_SET:
        return {
            ...state,
            address: '',
        };

    default:
        return state;
    }
};

const broadCast = (state = {
    inProgress: false,
    value: {},
}, action) => {
    switch (action.type) {
    case TX_SIGN_AND_BROAD_CAST_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case TX_SIGN_AND_BROAD_CAST_SUCCESS:
        return {
            inProgress: false,
            value: action.value,
        };
    case TX_SIGN_AND_BROAD_CAST_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const signTx = (state = {
    inProgress: false,
    value: {},
}, action) => {
    switch (action.type) {
    case TX_SIGN_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case TX_SIGN_SUCCESS:
        return {
            inProgress: false,
            value: action.value,
        };
    case TX_SIGN_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

export default combineReducers({
    broadCast,
    connection,
    signTx,
});
