import { combineReducers } from 'redux';
import {
    AMOUNT_VALUE_SET,
    CLEAR_CREATE_STREAM_SET,
    END_DATE_SET,
    IBC_TOKENS_LIST_FETCH_ERROR,
    IBC_TOKENS_LIST_FETCH_IN_PROGRESS,
    IBC_TOKENS_LIST_FETCH_SUCCESS,
    NUMBER_OF_PERIODS_SET,
    STOP_STREAMING_SET,
    TO_ADDRESS_SET,
    TOKEN_VALUE_SET,
    TYPE_VALUE_SET,
} from '../constants/create';
import { tokensList } from '../utils/defaultOptions';

const toAddress = (state = {
    value: '',
    valid: true,
}, action) => {
    switch (action.type) {
    case TO_ADDRESS_SET:
        return {
            ...state,
            value: action.value,
            valid: action.valid,
        };
    case CLEAR_CREATE_STREAM_SET:
        return {
            value: '',
            valid: true,
        };

    default:
        return state;
    }
};

const typeValue = (state = {
    value: 'continuous',
}, action) => {
    switch (action.type) {
    case TYPE_VALUE_SET:
        return {
            ...state,
            value: action.value,
        };
    case CLEAR_CREATE_STREAM_SET:
        return {
            value: 'continuous',
        };

    default:
        return state;
    }
};

const token = (state = {
    value: tokensList[0],
}, action) => {
    switch (action.type) {
    case TOKEN_VALUE_SET:
        return {
            ...state,
            value: action.value,
        };
    case CLEAR_CREATE_STREAM_SET:
        return {
            value: tokensList[0],
        };

    default:
        return state;
    }
};

const amount = (state = {
    value: '',
}, action) => {
    switch (action.type) {
    case AMOUNT_VALUE_SET:
        return {
            ...state,
            value: action.value,
        };
    case CLEAR_CREATE_STREAM_SET:
        return {
            value: '',
        };

    default:
        return state;
    }
};

const endDate = (state = {
    value: '',
    valid: true,
}, action) => {
    switch (action.type) {
    case END_DATE_SET:
        return {
            ...state,
            value: action.value,
            valid: action.valid,
        };
    case CLEAR_CREATE_STREAM_SET:
        return {
            value: '',
            valid: true,
        };

    default:
        return state;
    }
};

const stopStreaming = (state = {
    value: false,
}, action) => {
    switch (action.type) {
    case STOP_STREAMING_SET:
        return {
            ...state,
            value: action.value,
        };
    case CLEAR_CREATE_STREAM_SET:
        return {
            value: false,
        };

    default:
        return state;
    }
};

const numberOfPeriods = (state = {
    value: '',
}, action) => {
    switch (action.type) {
    case NUMBER_OF_PERIODS_SET:
        return {
            ...state,
            value: action.value,
        };
    case CLEAR_CREATE_STREAM_SET:
        return {
            value: '',
        };

    default:
        return state;
    }
};

const ibcTokensList = (state = {
    inProgress: false,
    value: [],
}, action) => {
    switch (action.type) {
    case IBC_TOKENS_LIST_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case IBC_TOKENS_LIST_FETCH_SUCCESS:
        return {
            inProgress: false,
            value: action.value,
        };
    case IBC_TOKENS_LIST_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

export default combineReducers({
    toAddress,
    typeValue,
    token,
    amount,
    endDate,
    stopStreaming,
    numberOfPeriods,
    ibcTokensList,
});
