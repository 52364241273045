export const TO_ADDRESS_SET = 'TO_ADDRESS_SET';
export const TYPE_VALUE_SET = 'TYPE_VALUE_SET';
export const TOKEN_VALUE_SET = 'TOKEN_VALUE_SET';
export const AMOUNT_VALUE_SET = 'AMOUNT_VALUE_SET';
export const END_DATE_SET = 'END_DATE_SET';
export const STOP_STREAMING_SET = 'STOP_STREAMING_SET';
export const NUMBER_OF_PERIODS_SET = 'NUMBER_OF_PERIODS_SET';
export const CLEAR_CREATE_STREAM_SET = 'CLEAR_CREATE_STREAM_SET';

export const IBC_TOKENS_LIST_FETCH_IN_PROGRESS = 'IBC_TOKENS_LIST_FETCH_IN_PROGRESS';
export const IBC_TOKENS_LIST_FETCH_SUCCESS = 'IBC_TOKENS_LIST_FETCH_SUCCESS';
export const IBC_TOKENS_LIST_FETCH_ERROR = 'IBC_TOKENS_LIST_FETCH_ERROR';
